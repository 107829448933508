const { APP_DOMAIN } = process.env;

// Extraemos la ruta para el backend, el cual siempre será "-api"
function getUrlServer() {
  // Si es localhost usamos el puerto 8000 como API
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return `${window.location.protocol}//${window.location.hostname}:8000`;
  }

  // Obtenemos el subdominio
  const enterprise = window.location.hostname.split('.');
  const subdomain = enterprise[0];
  const domain = enterprise.slice(1).join('.');
  // Creamos la url del api con el "-api"
  return `${window.location.protocol}//${subdomain}-api.${domain}`;
}

const url_server = getUrlServer();

export const url_logo = `${url_server}/storage/logo.png?${(new Date()).getTime()}`;

export default url_server;
