import { colors } from '@material-ui/core';

const white = '#ffffff';

export default {
  primary: {
    contrastText: white,
    dark: colors.grey[900],
    main: '#11192A',
    light: colors.grey[50],
    body: '#F1F4F8',
    cam: '#6a67ff',
    disabled: '#ccc'
  },
  iconTextUnlock: {
    main: '#fff'
  },
  iconTextLock: {
    main: '#fff',
  },
  secondary: {
    contrastText: white,
    dark: colors.grey[900],
    main: '#24263A',
    light: colors.grey[100]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[500]
  },
  text: {
    primary: colors.grey[50],
    secondary: colors.grey[900],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[900],
  background: {
    primary: '#212121',
    secondary: '#6A67FF',
    default: '#11192A',
    paper: white
  },
  modal: {
    backgroundColor: colors.grey[900]
  },
};
