import moment from 'moment';
import { url_logo } from 'src/constants/server';
import NotificationsService from 'src/services/NotificationsService';

const throwNotification = ({title, content, id}) => {
  const data = {
    icon: url_logo,
    image: url_logo,
    body: content,
    tag: id,
    renotify: true
  };

  if (Notification.permission === 'granted') {
    // Si esta correcto lanzamos la notificación
    let notificacion = new Notification(title, data);
    notificacion.onclick = function() {
      window.location.href = '/notifications';
    };
    //const notification = new Notification(message);
  }

  // Si no, tendremos que pedir permiso al usuario
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function(permission) {
      // Si el usuario acepta, lanzamos la notificación
      if (permission === 'granted') {
        let notificacion = new Notification(title, data);
        notificacion.onclick = function() {
          window.location.href = '/notifications';
        };
        //const notification = new Notification(message);
      }
    });
  }

};

const DesktopNotification = date => {
  let formato = moment(date).format('YYYY-MM-DD HH:mm:ss');
  NotificationsService.consultarNoLeidas(formato, 5)
    .then(data => {
      if(data.length > 4){
        throwNotification({
          title: 'Muchas notificaciones',
          content: 'Usted tiene varias notificaciones sin leer.',
          id: (new Date()).getTime()
        });
      }else{
        data.forEach(n => {
          throwNotification({
            title: n.titulo,
            content: n.contenido,
            id: n.id
          });
        });
      }
    })
};

export default DesktopNotification;
