import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Typography, useMediaQuery } from '@material-ui/core';
import url_server, { url_logo } from 'src/constants/server';
import { makeStyles } from '@material-ui/styles';
import Markdown from 'src/components/Markdown';

const useStyles = makeStyles((theme) => ({
  containerNotice: {
    //width: 1200,
    display: 'flex',
    flexDirection: 'row',
  },
  imgNoticeMainlarge: {
    minWidth: 600,
    width: 600,
    cursor: 'pointer',
  },
  imgNoticeMainMedium: {
    width: 440,
    minWidth: 440,
    cursor: 'pointer',
  },
  imgNoticeMainSmall: {
    width: 400,
    minWidth: 400,
    cursor: 'pointer',
  },
  imgNoticeMainExtraSmall: {
    width: '100%',
    cursor: 'pointer',
  },
  contentNoticeMain: {
    paddingLeft: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentNoticeMainMedium: {
    paddingLeft: 10,
    width: (validImg) => validImg ? '80%' : '30%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentNoticeMainSmall: {
    paddingLeft: 10,
    width: '30%',
    display: 'flex',
    flexDirection: 'column'
  },
  titleNoticeMain: {
    textTransform: 'capitalize',
    marginBottom: 16,
  },
  subtitleNoticeMain: {
    textTransform: 'capitalize',
    marginBottom: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '-moz-box-orient': 'vertical',
    'box-orient': 'vertical'
  },
  bodyNoticeMain: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    marginBottom: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    'line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    '-moz-box-orient': 'vertical',
    'box-orient': 'vertical',
    '& > p': {
      color: 'black',
    },
  },
  footerNoticeMain: {
    display: 'flex',
    justifyContent: 'end',
  },
  publishedNoticeMain: {
    paddingTop: 5,
    color: '#a7a7a7',
    flexGrow: 1,
  }
}));

const NoticeMain = ({ img, title, sub_title, content, created, onOpenModal, notice }) => {

  const [validImg, setValidImg] = useState(false);

  const lg = useMediaQuery('(max-width: 1560px)');
  const md = useMediaQuery('(max-width: 958px)');
  const sm = useMediaQuery('(max-width: 760px)');

  useEffect(() => {
    if (img === null) {
      setValidImg(true);
    } else {
      setValidImg(false);
    }
  }, [img]);

  const getClassNameToImg = () => {
    if (!lg) {
      return classes.imgNoticeMainlarge;
    }
    if (!md) {
      return classes.imgNoticeMainMedium;
    }
    if (!sm) {
      return classes.imgNoticeMainSmall;
    }
    return classes.imgNoticeMainExtraSmall;
  };

  const classes = useStyles({ validImg });

  return (
    <Card onClick={() => onOpenModal(notice)} style={{ cursor: 'pointer' }}>
      <CardContent>
        <div className={classes.containerNotice} style={{ flexWrap: (lg && md && sm) ? 'wrap' : 'inherit' }} >
          {
            (!validImg) ?
              (
                <img
                  className={getClassNameToImg()}
                  src={img ? `${url_server}/${img}` : url_logo}
                />
              )
              : <></>
          }
          <div className={String(!lg ? classes.contentNoticeMain : !md ? classes.contentNoticeMainMedium : sm && classes.contentNoticeMainSmall)}>
            <Typography className={classes.titleNoticeMain} variant='h1' component={'h1'}>{title}</Typography>
            <Typography className={classes.subtitleNoticeMain} variant='subtitle1' component={'div'}>{sub_title}</Typography>
            <Markdown
              className={classes.bodyNoticeMain}
              escapeHtml={false}
              source={notice?.content}
            />
            <Typography className={classes.publishedNoticeMain} variant='h6' component={'span'}>{created}</Typography>
            {
              !lg && (
                <div className={classes.footerNoticeMain}>
                  <Button
                    variant="contained"
                  >
                    Ver completa ...
                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default NoticeMain;