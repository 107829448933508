export default {
  label: {
    color: '#ccc',
    '&.MuiStepLabel-active': {
      color: '#11192a'
    },
    '&.MuiStepLabel-completed': {
      color: '#11192a'
    }
  },
};
