const subscribers = new Set();
let loading = 0;

const subscribe = subscriber => {
  subscribers.add(subscriber);

  return () => {
    unsubscribe(subscriber);
  };
};

const unsubscribe = subscriber => {
  subscribers.delete(subscriber);
};

const notify = () => {
  subscribers.forEach(subscriber => {
    subscriber();
  });
};

const setLoading = (doLoad) => {
  if(doLoad) {
    loading ++;
  } else {
    loading --;
    if(loading < 0){
      loading = 0;
    }
  }
  notify();
};

const getLoading = () => {
  return loading !== 0;
};

const UniversalLoading = {
  subscribe,
  unsubscribe,
  getLoading,
  setLoading,
};

export default UniversalLoading;
