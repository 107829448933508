import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NoticiasService from 'src/services/NoticiasService';
import Page from 'src/components/Page';
import { url_logo } from 'src/constants/server';
import NoticeMain from './NoticeMain';
import NoticeSmall from './NoticeSmall';
import ModalNotices from './ModalNotices';
import Loader from 'src/components/Loader';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: theme.spacing(3),
  },
  containerNoticeSmall: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 14,
  },
  header: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto',
    padding: '20px 24px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 24px'
    }
  },
  contentMedia: {
    justifyContent: 'center',
    width: 'calc(100vw - 290px)',
    height: 'calc(100vh - 330px)',
    maxWidth: 900,
    backgroundImage: `url(${url_logo})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 30px)',
      height: 'calc(100vh - 220px)',
    }
  },
  containerGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    // Estilos para pantallas más grandes que 1450px
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'space-between',
    },

    // Estilos para pantallas más grandes que 1280px
    [theme.breakpoints.between('lg', 'xl')]: {
      justifyContent: 'space-between',
    },

    // Estilos para pantallas más pequeñas que 1280px
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

const DEFAULT_LIMIT = 6;

const Notices = ({className, podium, ...rest}) => {
  const classes = useStyles();

  console.log(podium);

  const [notices, setNotices] = useState([]);
  const [noticeSelected, setNoticeSelected] = useState(null);
  const [openModalNotice, setOpenModalNotice] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);

  const getNotices = () => {
    setCargando(true);
    NoticiasService.getNewsCurrents(offset, limit)
      .then((response) => {
        setCargando(false);
        const newNotices = response.slice(0, limit - 1);
        setNotices([...notices, ...newNotices]);
        setIsFinished(response.length !== limit);
        setLimit(DEFAULT_LIMIT - 1);
        setOffset(notices.length + newNotices.length);
      })
      .catch((error) => {
        setCargando(false);
      })
  };

  useEffect(() => {
    getNotices();
  }, []);


  const handleOpenModalNotice = (notice) => {
    setOpenModalNotice(true);
    setNoticeSelected({ ...notice });
  };

  const handleCloseModalNotice = () => {
    setNoticeSelected(null);
    setOpenModalNotice(false);
  };

  return (
    <Page className={classes.root} title='Inicio'>
      <Container maxWidth="lg" className={classes.container}>
        {
          notices.length !== 0 ? (
            <Grid container>
              <Grid item xs={12}>
                {
                  podium ?
                  <></>
                  :
                  <NoticeMain
                    content={notices[0].content}
                    created={notices[0].created_at}
                    img={notices[0].image_path}
                    sub_title={notices[0].sub_title}
                    title={notices[0].title}
                    notice={notices[0]}
                    onOpenModal={handleOpenModalNotice}
                  />
                }
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: notices.length > 3 ? 'space-between' : 'center' }} >
                {
                  podium ?
                  notices.map((notice, indexNot) => (
                    <Grid key={indexNot} item xs={12} sm={4} md={3} lg={3} style={{ margin: '10px' }} className={classes.containerGrid}>
                      <NoticeSmall
                        notice={notice}
                        onOpenModal={handleOpenModalNotice}
                      />
                    </Grid>
                  ))
                  :
                  notices.filter((item, index) => index !== 0).map((notice, indexNot) => (
                    <Grid key={indexNot} item xs={12} sm={4} md={3} lg={3} style={{ margin: '10px' }} className={classes.containerGrid}>
                      <NoticeSmall
                        notice={notice}
                        onOpenModal={handleOpenModalNotice}
                      />
                    </Grid>
                  ))
                }
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: 10 }} >
                <Button variant="contained" onClick={getNotices} disabled={isFinished}>
                  Ver más...
                </Button>
              </Grid>
            </Grid>
          )
            :
            (
              <div className={classes.header}>
                <Typography
                  align="center"
                  variant='h1'
                  style={{ color: '#000000' }}
                >
                  ¡BIENVENIDOS!
                </Typography>
                <div className={classes.contentMedia}> </div>
              </div>
            )

        }
      </Container>
      <ModalNotices
        notice={noticeSelected}
        onClose={handleCloseModalNotice}
        open={openModalNotice}
      />
      <Loader open={cargando} />
    </Page>
  )
}

export default Notices;
