import React, { useEffect, useState, useCallback } from 'react';
import ModalNotifications from '../ModalNotifications';
import { Badge, IconButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationObservable from 'src/observers/NotificationObservable';

const Bell = () => {
  const [modalNotifications, setModalNotifications] = useState(false);
  const [notRead, setNotRead] = useState(0);

  useEffect(() => {
    setNotRead(NotificationObservable.getNotRead());
    const unsubscribe = NotificationObservable.subscribe(() => {
      setNotRead(NotificationObservable.getNotRead());
    });

    return unsubscribe;
  }, []);

  const pathname = window.location.pathname;

  const handleDesplegar = () => {
    setModalNotifications(true);
  };

  return (
    <>
      <IconButton
        color="primary"
        onClick={() => {
          if (pathname !== '/notifications') {
            handleDesplegar();
          }
        }}
      >
        <Badge
          label="Notificaciones"
          badgeContent={notRead}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {
        modalNotifications
          ? <ModalNotifications
            open={modalNotifications}
            onClose={() => {
              setModalNotifications(false);
            }}
          />
          : <></>
      }
    </>
  );
};

export default Bell;
