import axios from "axios";
import moment from "moment";
import url_api from "src/constants/apiJasmine";

const getNews = async (query, page, limit, sedeId) => {
  try {
    const response = await axios
      .get(`${url_api}news?page=${page}&limit=${limit}&q=${query}`,
        {
          params: {
            sede_id: sedeId === -1 ? null : sedeId
          }
        });
    return response.data.data;

  } catch (error) {
    return error;
  }
};

//GET/news/current

const getNewsCurrents = async (offset, limit = 17) => {
  try {
    const response = await axios
      .get(`${url_api}news/current?offset=${offset}&limit=${limit}`)
    return response.data;
  } catch (error) {
    return error;
  }
};

const createNews = async (notice) => {

  const formulario = new FormData();

  formulario.append('title', notice.title);
  formulario.append('content', notice.content);

  notice.sedes.forEach((element, index) => {
    formulario.append('sedes[][id]', element.id)
  });

  if (notice.directed_to) {
    if (notice.directed_to !== null) {
      formulario.append('directed_to', notice.directed_to);
    }
  };

  if (notice.expiration_date) {
    formulario.append('expiration_date', moment(notice.expiration_date).format('YYYY-MM-DD'));
  };
  if (notice.publication_date) {
    formulario.append('publication_date', moment(notice.publication_date).format('YYYY-MM-DD'));
  };
  /* 
    Ejemplo de envío de arreglo por FormData();
  formulario.append('sedes[0][id]', notice.sedes[0].id);
  formulario.append('sedes[0][name]', 'erick');
  formulario.append('sedes[1][id]', 5000);
  formulario.append('sedes[1][name]', 'damian');
 */

  if (notice.sub_title) {
    formulario.append('sub_title', notice.sub_title);
  };

  if (notice.image_path) {
    formulario.append('image', notice.image_path);
  };

  const response = await axios.post(`${url_api}news`, formulario)
  return response;
};


/**
 * UPDATE NOTICE
 * @param {*} notice 
 * @returns 
 */
const updateNews = async (notice) => {


  const formulario = new FormData();

  formulario.append('title', notice.title);
  formulario.append('content', notice.content);

  notice.sedes.forEach((element, index) => {
    formulario.append('sedes[][id]', element.id)
  });

  if (notice.sub_title) {
    formulario.append('sub_title', notice.sub_title);
  };

  if (notice.expiration_date) {
    formulario.append('expiration_date', moment(notice.expiration_date).format('YYYY-MM-DD'));
  };

  if (notice.publication_date) {
    formulario.append('publication_date', moment(notice.publication_date).format('YYYY-MM-DD'));
  };

  if (notice.image_path) {
    if (typeof notice.image_path !== 'string') {
      formulario.append('image', notice.image_path);
    };
  }

  if (notice.directed_to) {
    if (notice.directed_to !== null) {
      formulario.append('directed_to', notice.directed_to);
    }
  };

  const response = await axios.post(`${url_api}news/${notice.id}`, formulario)
  return response;
};

const NewsServices = {
  getNews,
  createNews,
  updateNews,
  getNewsCurrents,
};

export default NewsServices;

