import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Paper, Avatar, colors, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ConcursosService from 'src/services/ConcursosService';
import Loader from 'src/components/Loader';
import url_server from 'src/constants/server';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  centerer: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  podiums: {
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop:"20px",
    // margin: '20px auto 100px auto',
    minHeight: '350px',
  },
  podium: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    flexWrap: 'wrap',
    flex: 1,
    top: 0,
    // transition: 'all 500ms ease-in-out',
  },
  podiumBase: {
    height: 0,
    background: "#ececec",
    color: '#fff',
    maxWidth: "calc(100%)",
    // minWidth: '150px',
    borderRadius: '5px',
    // transition: 'all 1000ms ease-in-out',
    position: 'relative',
  },
  podiumBaseFirst: {
    background: `linear-gradient(to bottom, #FFBF00 0%, #FFBF00 100%)`,
  },
  podiumBaseSecond: {
    background: `linear-gradient(to bottom, #8A9597 0%, #8A9597 100%)`,
  },
  podiumBaseThird: {
    background: `linear-gradient(to bottom, #CD7F32 0%, #CD7F32 100%)`,
  },
  podiumNumber: {
    textAlign: 'center',
    // fontSize: '1.5rem',
    maxWidth: "100%",
    marginTop: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  podiumRank: {
    textAlign: 'center',
    fontSize: '2.5rem',
    maxWidth: "100%",
    marginTop: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  podiumDescriptionGoal: {
    textAlign: 'center',
    width: "100%",
    height: "50px",
    maxWidth: "calc(100% - 10px)",
    maxHeight: "calc(100% - 10px)",
    fontSize: '1.0rem',
    marginTop: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  podiumGoal: {
    textAlign: 'center',
    fontSize: '1.0rem',
    width: "100%",
    height: "15px",
    maxHeight: "calc(100% - 10px)",
    maxWidth: "calc(100% - 10px)",
    // height: "100%",
    marginTop: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  podiumGoalPrice: {
    textAlign: 'center',
    fontSize: '1.0rem',
    width: "100%",
    height: "20px",
    maxHeight: "calc(100% - 10px)",
    maxWidth: "calc(100% - 10px)",
    // height: "100%",
    marginTop: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  podiumImage: {
    marginTop: '10px',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& img': {
      width: "100%",
      height: "100%",
      maxWidth: '150px',
      maxHeight: '150px',
      objectFit: 'cover',
      overflow: 'hidden',
      borderRadius: '50%',
    },
  },
}));

const DEFAULT_LIMIT = 6;

const Podium = ({handleExistPodium, handleNotExistPodium, statePodium, ...rest}) => {
  const classes = useStyles();

  const [podium, setPodium] = useState([]);
  const [cargando, setCargando] = useState(false);

  const getNotices = () => {
    setCargando(true);
    ConcursosService.getPodium()
      .then((response) => {
        setCargando(false);
        setPodium(response.data);
        handleExistPodium();
      })
      .catch((error) => {
        setCargando(false);
        handleNotExistPodium();
      })
  };

  useEffect(() => {
    getNotices();
  }, []);

  return (
    <>
      {
        statePodium ?
        <Container maxWidth="md" className={classes.container}>
          
          <Typography
            align="center"
            variant='h3'
            style={{ color: '#000000' }}
          >
            HALL OF FAME
          </Typography>
          <Typography
            align="center"
            variant='h3'
            style={{ color: '#000000' }}
          >
            {moment(podium?.competition?.date_start).format('DD/MM/YYYY')} a {moment(podium?.competition?.date_end).format('DD/MM/YYYY')}
          </Typography>
          <Grid>
            <Grid container spacing={3} className={classes.podiums}>
              <Grid item xs={4} md={4} className={`${classes.podium}`}>
                <Grid className={classes.podiumImage}>
                  {
                  podium.second?.url ? 
                    <img src={`${url_server}/${podium.second?.url}`}/>
                  :
                    <Avatar />
                  }
                </Grid>
                <Grid className={classes.podiumNumber}>{podium.second?.nickname}</Grid>
                <Paper className={`${classes.podiumBase} ${classes.podiumBaseSecond}`} style={{ height: '250px' }}>
                  <Grid className={classes.podiumRank}>2</Grid>
                  <Tooltip title={podium.second_goal?.description ? podium.second_goal?.description : ""}>
                    <Grid className={classes.podiumDescriptionGoal}>{podium.second_goal?.description}</Grid>
                  </Tooltip>
                  <Grid className={classes.podiumGoal}>PREMIO</Grid>
                  <Grid className={classes.podiumGoalPrice}>{podium.second_goal?.goal ? podium.second_goal.goal : ''}</Grid>
                </Paper>
                <Typography
                  align="center"
                  variant='h6'
                  style={{ color: '#000000', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                >
                  Puntos Actuales: {podium.second?.points ? podium.second?.points : 0 }
                </Typography>
                <Typography
                  align="center"
                  variant='h6'
                  style={{ color: '#000000', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                >
                  Puntos mínimos para ganar: {podium.second_goal?.points_min ? podium.second_goal?.points_min : 0}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} className={`${classes.podium}`}>
                <Grid className={classes.podiumImage}>
                  {
                    podium.first?.url ? 
                      <img src={`${url_server}/${podium.first?.url}`}/>
                    :
                      <Avatar />
                  }
                </Grid>
                <Grid className={classes.podiumNumber}>{podium.first?.nickname}</Grid>
                <Paper className={`${classes.podiumBase} ${classes.podiumBaseFirst}`} style={{ height: '300px' }}>
                  <Grid className={classes.podiumRank}>1</Grid>
                  <Tooltip title={podium.first_goal?.description ? podium.first_goal?.description : ""}>
                    <Grid className={classes.podiumDescriptionGoal}>{podium.first_goal?.description}</Grid>
                  </Tooltip>
                  <Grid className={classes.podiumGoal}>PREMIO</Grid>
                  <Grid className={classes.podiumGoalPrice}>{podium.first_goal?.goal ? podium.first_goal.goal : ''}</Grid>
                </Paper>
                <Typography
                  align="center"
                  variant='h6'
                  style={{ color: '#000000', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                >
                  Puntos Actuales: {podium.first?.points ? podium.first?.points : 0 }
                </Typography>
                <Typography
                  align="center"
                  variant='h6'
                  style={{ color: '#000000', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                >
                  Puntos mínimos para ganar: {podium.third_goal?.points_min ? podium.third_goal?.points_min : 0}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} className={`${classes.podium}`}>
                <Grid className={classes.podiumImage}>
                  {
                    podium.third?.url ? 
                      <img src={`${url_server}/${podium.third?.url}`}/>
                    :
                      <Avatar />
                  }
                </Grid>
                <Grid className={classes.podiumNumber}>{podium.third?.nickname}</Grid>
                <Paper className={`${classes.podiumBase} ${classes.podiumBaseThird}`} style={{ height: '200px' }}>
                  <Grid className={classes.podiumRank}>3</Grid>
                  <Tooltip title={podium.third_goal?.description ? podium.third_goal?.description : ""}>
                    <Grid className={classes.podiumDescriptionGoal}>{podium.third_goal?.description}</Grid>
                  </Tooltip>
                  <Grid className={classes.podiumGoal}>PREMIO</Grid>
                  <Grid className={classes.podiumGoalPrice}>{podium.third_goal?.goal ? podium.third_goal.goal : ''}</Grid>
                </Paper>
                <Typography
                  align="center"
                  variant='h6'
                  style={{ color: '#000000', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                >
                  Puntos Actuales: {podium.third?.points ? podium.third?.points : 0 }
                </Typography>
                <Typography
                  align="center"
                  variant='h6'
                  style={{ color: '#000000', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                >
                  Puntos mínimos para ganar: {podium.first_goal?.points_min ? podium.first_goal?.points_min : 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        :
        <></>
      }
      <Loader open={cargando} />
    </>
  )
}

export default Podium;
