import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import Loader from 'src/components/Loader';
import NotificationsService from 'src/services/NotificationsService';
import ModalNotifications from '../../Modals/ModalNotifications';
import Notification from '../Notification';
import LensIcon from '@material-ui/icons/Lens';
import moment from 'moment';
import { url_logo } from 'src/constants/server';
import { makeStyles } from '@material-ui/styles';

const getDateFormated = date => {
  const mDate = moment(date);
  const mNow = moment();

  // Si ha transcurrido menos de una hora
  if (mNow - mDate <= 3600000) {
    return mDate.startOf('minutes').fromNow() + ' Aproximadamente';
  }

  // Si ha transcurrido menos de una hora
  if (mNow - mDate < 86400000) {
    return mDate.startOf('hours').fromNow() + ' Aproximadamente';
  }

  // Si ha transcurrido menos de una hora
  return mDate.format('dddd, DD/MMMM/YY HH:mm');
};

const Notificaciones = ({ open, onClose }) => {

  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState('');
  const [notificaciones, setNotificaciones] = useState([]);
  const [openModalVerNotificacion, setOpenModalVerNotificacion] = useState(
    false
  );
  const [notificacionId, setNotificacionId] = useState(undefined);
  const [lastNotificationId, setLastNotificationId] = useState(null);
  const [limite, setLimite] = useState(10);
  //Api
  const consultarApi = useCallback(() => {
    setCargando(true);
    NotificationsService.listarNotificaciones(lastNotificationId, 10)
      .then(data => {
        setLimite(data.length);
        setNotificaciones([...notificaciones, ...data]);
        setCargando(false);
      })
      .catch(errors => {
        const mensaje =
          'Error al consultar las notificaciones. ' +
          (errors.response.data.message ? errors.response.data.message : '');
        setError(mensaje);
        setCargando(false);
      });
  }, [lastNotificationId]);

  useEffect(() => {
    consultarApi();
  }, [lastNotificationId]);

  const handleVerNotificacion = id => {
    setNotificacionId(id);
    setOpenModalVerNotificacion(true);
  };
  const handleCloseVerNotificacion = () => {
    setOpenModalVerNotificacion(false);
    const index = notificaciones.findIndex(
      notificacion => notificacion.id === notificacionId
    );
    notificaciones[index].leido = true;
    notificaciones[index].fecha_lectura = moment().format('YYYY-MM-DD HH:mm');
    setNotificaciones([...notificaciones]);
  };
  const handleVerMas = () => {
    setLastNotificationId(notificaciones[notificaciones.length - 1].id);
  };

  return (
    <ModalNotifications
      content={
        <Grid container>
          {notificaciones.map(notificacion => (
            <Grid
              key={notificacion.id}
              container
              redirection="column"
              alignItems="center"
              onClick={() => {
                handleVerNotificacion(notificacion.id);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Grid item xs={2} align="center">
                {!notificacion.leido
                  ? <img src={url_logo} style={{
                    width: 30,
                    borderRadius: "50%",
                  }} />
                  : <LensIcon
                    style={{ color: '#6A67FF' }}
                  />
                }
              </Grid>
              <Grid item xs={10}>
                <Grid>
                  <Typography
                    style={{
                      font: "Raleway 120% sans-serif",
                      fontSize: 14,
                      fontWeight: notificacion.leido ? 'normal' : 'bold'
                    }}
                  >
                    {notificacion.titulo.substr(0, 24) +
                      (notificacion.titulo.length > 24 ? '...' : '')
                    }
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      font: "Raleway 120% sans-serif",
                      fontSize: 11,
                      fontWeight: notificacion.leido ? 'normal' : 'bold'
                    }}
                  >
                    {getDateFormated(notificacion.created_at)}
                  </Typography>
                </Grid>
              </Grid>
              <hr color="gray" width="230" />
              <br />
            </Grid>
          ))}
          {limite < 10 || notificaciones.length < limite ? (
            ''
          ) : (
            <Grid container direction="column" align="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerMas}
                  disabled={limite < 10}
                >
                  Ver Más
                </Button>
              </Grid>
            </Grid>
          )}
          <Loader open={cargando} />
          <Notification
            open={openModalVerNotificacion}
            onClose={() => {
              handleCloseVerNotificacion();
            }}
            notificacionId={notificacionId}
            notificaciones={notificaciones}
          />
        </Grid>
      }
      open={open}
      onClose={() => {
        setLimite(10);
        onClose();
      }}
    />
  );
};

export default Notificaciones;
