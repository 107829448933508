import axios from 'axios';
import url_api from './../constants/apiJasmine';
import moment from 'moment';
import PermissionObservable from 'src/observers/PermissionObservable';
import NotificationObservable from 'src/observers/NotificationObservable';
import PeriodObservable from 'src/observers/PeriodObservable';

/**
 * Actualiza los permisos en caso de que lleguen en la cabecera.
 * @param {array} headers 
 */
const updatePermissions = (headers) => {
    // En el caso en que los permisos lleguen en la cabecera
    if (headers && headers['x-afl-permissions']) {
        const newPermissions = JSON.parse(headers['x-afl-permissions']);
        PermissionObservable.setPermissions(newPermissions);
    }
    // Se consultan las notificaciones
    if (headers && headers['x-afl-notifications']) {
        const newNotifications = JSON.parse(headers['x-afl-notifications']);
        NotificationObservable.setBuzon(
            newNotifications.cantidad_no_leidos,
            newNotifications.updated_at
        );
    }
    // Se consulta la cantidad de días que tiene un período
    if (headers && headers['x-xara-period-days']) {
        const newPeriodDays = JSON.parse(headers['x-xara-period-days']);
        PeriodObservable.setPeriodDays(
            newPeriodDays,
        );
        localStorage.setItem('periodDays', newPeriodDays);
    }
    // Se consulta el día inicial de conteo de los períodos
    if (headers && headers['x-xara-initial-date']) {
        const newPeriodFirstDate = JSON.parse(headers['x-xara-initial-date']);
        PeriodObservable.setPeriodFirstDate(
            newPeriodFirstDate,
        );
        localStorage.setItem('periodFirstDate', newPeriodFirstDate);
    }
};

const configureAxios = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Accept'] = 'application/json, text/plain, */*';

    axios.interceptors.response.use((response) => {
        updatePermissions(response.headers);
        return response;
    }, (error) => {

        updatePermissions(error?.response?.headers);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('token_expires_at');
                localStorage.removeItem('user');
                PermissionObservable.clearPermissions();
                window.location = '/auth/login';
            }
            let message = `Error desconocido: ${error.message}`;
        
            switch (error.response.status) {
                case 403:
                    message = 'No tiene permisos para esta acción.';
                    break;
                case 404:
                    message = 'La ruta a la que intenta acceder no existe.';
                    break;
                default:
                    return Promise.reject(error);
            }
            return Promise.reject({
                response: {
                    data: {
                        message
                    }
                }
            });
        } else if (error.request) {
            let message = null;
            if (error.message && error.message.toLowerCase() === 'network error') {
                message = 'No hay conexión a la red. Intente nuevamente. ';
            } else {
                message = 'No hay respuesta del servidor.';
            }
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return Promise.reject({
                response: {
                    data: {
                        message
                    }
                }
            });
        } else {
            // Something happened in setting up the request that triggered an Error
            return Promise.reject({
                response: {
                    data: {
                        message: error.message
                    }
                }
            });
        }
    });
}

const errorResponseFunction = (error) => {
    if (!error.response) {
        return {
            message: 'El servidor no ha respondido correctamente.',
            success: false
        };
    }

    let mensaje = [];
    if (error.response.data.errors) {
        for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
        }
    } else {
        mensaje.push(error.response.data.message);
    }

    return {
        message: mensaje.join(' - '),
        success: false
    };
}

const successResponseFunction = response => {
    response.data.success = true;
    return response.data;
}

const login = (email, password) => {
    return axios.post(url_api + `auth/login`,
        {
            email: email,
            password: password
        }
    )
        .then(
            (response) => {
                response.data.success = true;
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('token_expires_at', response.data.expires_at);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                configureAxios();
                updatePermissions(response.headers);

                return {
                    success: true,
                    message: 'Login exitoso',
                    data: response.data
                };
            },
            (error) => {
                if (!error.response) {
                    return {
                        message: 'El servidor no ha respondido correctamente.',
                        success: false
                    };
                }

                if (error.response.status === 401) {
                    return {
                        message: 'Credenciales incorrectas',
                        success: false
                    };
                }

                let mensaje = [];
                if (error.response.data.errors) {
                    for (let i in error.response.data.errors) {
                        mensaje.push(error.response.data.errors[i][0]);
                    }
                } else {
                    mensaje.push(error.response.data.message);
                }

                return {
                    message: mensaje.join(' - '),
                    success: false
                };
            }
        );
};

const recoveryPassword = (email) => {
    return axios.post(url_api + 'auth/recovery-password', { email }).then(
        response => {
            response.data.success = true;
            return response.data;
        },
        error => {
            let mensaje = [];
            if (error.response.data.errors) {
                for (let i in error.response.data.errors) {
                    mensaje.push(error.response.data.errors[i][0]);
                }
            } else {
                mensaje.push(error.response.data.message);
            }

            return {
                message: mensaje.join(' - '),
                success: false
            };
        }
    );
};

const resetPassword = (email, token, password, password_confirmation) => {
    return axios.post(url_api + 'auth/reset-password', { email, token, password, password_confirmation }).then(
        response => {
            response.data.success = true;
            return response.data;
        },
        error => {
            let mensaje = [];
            if (error.response.data.errors) {
                for (let i in error.response.data.errors) {
                    mensaje.push(error.response.data.errors[i][0]);
                }
            } else {
                mensaje.push(error.response.data.message);
            }

            return {
                message: mensaje.join(' - '),
                success: false
            };
        }
    );
};

const logout = () => {
    return axios.get(url_api + `auth/logout`)
        .then(
            (response) => {
                response.data.success = true;
                localStorage.removeItem('token');
                localStorage.removeItem('token_expires_at');
                localStorage.removeItem('user');
                PermissionObservable.clearPermissions();
                return response.data;
            }
        );
};

const permissions = () => {
    const permissions = localStorage.getItem('permissions');
    if (permissions)
        return localStorage.getItem('permissions');
    else
        return [];
}

const can = (permission) => {
    return permissions().indexOf(permission) !== -1;
};

const user = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    // En caso de que se haya guardado por error un string (404)
    if (typeof userData === 'string') {
        logout()
            .then(() => {
            }).catch(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('token_expires_at');
                localStorage.removeItem('user');
                localStorage.removeItem('permissions');
                PermissionObservable.clearPermissions();
                window.location = '/';
            });
        return {};
    }
    return userData;
}

const refreshToken = () => {
    moment.locale('es')
    var date = moment().format('YYYY-MM-DD HH:mm:ss');
    var expires_at = localStorage.getItem('token_expires_at');
    var expires_more = moment(expires_at).set('minute', 5).format('YYYY-MM-DD HH:mm:ss');
    if (localStorage.getItem('token')) {
        if (moment(date).isAfter(expires_at)) {
            if (moment(date).isAfter(expires_more)) {
                return axios.post(url_api + `auth/refreshToken/`)
                    .then(
                        (response) => {
                            localStorage.setItem('token_expires_at', response.data.data);
                        }
                    );
            }
        }
    } else {
    }
}

const refreshUser = () => {
    return axios.get(`${url_api}auth/user`)
        .then((response) => {
            localStorage.setItem('user', JSON.stringify(response.data));
            return response.data;
        });
}

const AuthService = {
    errorResponseFunction,
    successResponseFunction,
    configureAxios,
    login,
    logout,
    permissions,
    can,
    user,
    refreshToken: refreshToken,
    recoveryPassword,
    resetPassword,
    refreshUser,
};

export default AuthService;