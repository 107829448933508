import palette from '../palette';

export default {
  input: {
    color: palette.text.secondary,
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
      '&::placeholder': {
        color: palette.text.secondary,
      },
    },
  },
};
