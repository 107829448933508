const subscribers = new Set();
let countNoRead = 0;
let updatedAt = null;

const subscribe = subscriber => {
  subscribers.add(subscriber);

  return () => {
    unsubscribe(subscriber);
  };
};

const unsubscribe = subscriber => {
  subscribers.delete(subscriber);
};

const notify = () => {
  subscribers.forEach(subscriber => {
    subscriber();
  });
};

const getNotRead = () => {
  return countNoRead;
};

const getUpdatedAt = () => {
  return updatedAt;
};

const setBuzon = (newNotRead, newUpdatedAt) => {
  countNoRead = newNotRead;
  updatedAt = newUpdatedAt;
  notify();
};



const NotificationObservable = {
  subscribe,
  unsubscribe,
  getNotRead,
  getUpdatedAt,
  setBuzon
};

export default NotificationObservable;
