import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';

import PropTypes from 'prop-types';
const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18
  },
  container: {
    padding: 20
  }
}));

const getIcon = (t) => {
  if (t === 'success') {
    return <CheckCircleIcon />;
  }
  if (t === 'warning') {
    return <WarningIcon />;
  }
  if (t === 'error') {
    return <CancelIcon />;
  }
};

const getTitle = (type, title) => {
  if(!title){
    if (type === 'success') {
      return 'Éxito';
    }
    if (type === 'warning') {
      return 'Alerta';
    }
    if (type === 'error') {
      return 'Error';
    }
  }
  return title;
};

function Success({ message, onClose, open, title, type }) {
  const classes = useStyles();


  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      type={type}
    >
      <DialogTitle id="simple-dialog-title">
        <span className={classes.title}>
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
          >
            {getIcon(type)}
            {getTitle(type, title)}
          </Grid>
        </span>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
    </Dialog>
  );
}

Success.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string
};

Success.defaultProps = {
  type: 'info',
  title: ''
};

export default Success;
