import React, { useState, useEffect, useRef } from 'react';
import NotificationObservable from 'src/observers/NotificationObservable';
import DesktopNotification from 'src/views/Notifications/DesktopNotification';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const ShowNewNotifications = () => {
  const [newUpdatedAt, setNewUpdatedAt] = useState(null);
  const updatedAt = usePrevious(newUpdatedAt);

  useEffect(() => {
    if (updatedAt !== null && newUpdatedAt !== null && updatedAt <= newUpdatedAt) {
      // mostrar las nuevas notificaciones
      DesktopNotification(updatedAt);
    }
  }, [newUpdatedAt, updatedAt]);

  useEffect(() => {
    const unsubscribe = NotificationObservable.subscribe(() => {
      setNewUpdatedAt(NotificationObservable.getUpdatedAt());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
    </>
  );
};

export default ShowNewNotifications;
