import axios from 'axios';
import url_api from './../constants/apiJasmine';
import moment from 'moment';
import PeriodObservable from 'src/observers/PeriodObservable';
import { getMessageFromErrorResponse } from 'src/utils/getMessageFromErrorResponse';
import getPeriodDays from 'src/utils/getPeriodDays';
import { downloadGetFile, downloadPostFile } from 'src/utils/downloadFile';

const crearModelo = modelo => {
  modelo.user.fecha_nacimiento = moment(modelo.user.fecha_nacimiento).format(
    'YYYY-MM-DD'
  );
  modelo.user.contrato.fecha_inicio = moment(
    modelo.user.contrato.fecha_inicio
  ).format('YYYY-MM-DD');

  return axios.post(url_api + 'jasmine-model', modelo)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const actualizarModelo = modelo => {
  const newModelo = {
    ...modelo,
    goals: modelo.goals ?? []
  };

  if (newModelo.user.fecha_nacimiento) {
    newModelo.user.fecha_nacimiento = moment(newModelo.user.fecha_nacimiento).format(
      'YYYY-MM-DD'
    );
  }

  if (newModelo.user.contrato?.fecha_inicio) {
    newModelo.user.contrato.fecha_inicio = moment(
      newModelo.user.contrato.fecha_inicio
    ).format('YYYY-MM-DD');
  }

  return axios.patch(url_api + 'jasmine-model/' + newModelo.id, newModelo)
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const enviarImagenPerfil = (id, imagen) => {
  var data = new FormData();
  data.append('foto_modelo', imagen);
  return axios.post(url_api + `jasmine-model/${id}/foto-modelo`, data)
    .then(() => Promise.resolve(true))
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const obtener = (search, limit, page, sedeId) => {
  return axios
    .get(url_api + `jasmine-model?page=${page}&limit=${limit}&q=${search}`, { params: { sede_id: sedeId === -1 ? null : sedeId } })
    .then(
      response => {
        response.data.success = true;
        return response.data;
      }
    );
};

const obtenerPassword = id => {
  return axios.get(url_api + `jasmine-model/${id}/password`).then(
    response => {
      response.data.success = true;
      return response.data;
    },
    error => {
      let mensaje = [];
      if (error.response.data.errors) {
        for (let i in error.response.data.errors) {
          mensaje.push(error.response.data.errors[i][0]);
        }
      } else {
        mensaje.push(error.response.data.message);
      }

      return {
        message: mensaje.join(' - '),
        success: false
      };
    }
  );
};

const obtenerPasswordCorporativo = id => {
  return axios.get(url_api + `jasmine-model/${id}/password-corporativo`).then(
    response => {
      response.data.success = true;
      return response.data;
    },
    error => {
      let mensaje = [];
      if (error.response.data.errors) {
        for (let i in error.response.data.errors) {
          mensaje.push(error.response.data.errors[i][0]);
        }
      } else {
        mensaje.push(error.response.data.message);
      }

      return {
        message: mensaje.join(' - '),
        success: false
      };
    }
  );
};

const inactivar = ({ modelo_id, motivo_id }) => {

  return axios
    .post(`${url_api}jasmine-model/${modelo_id}/retirar/${motivo_id}`)
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return {
          message: mensaje.join(' - '),
          success: false
        };
      }
    );
};

const reactivar = motivo => {
  return axios
    .post(url_api + 'jasmine-model/movimiento-modelo/reactivar', motivo)
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return {
          message: mensaje.join(' - '),
          success: false
        };
      }
    );
};

const activar = modelo_id => {
  return axios.post(url_api + `jasmine-model/${modelo_id}/activar`, {}).then(
    response => {
      response.data.success = true;
      return response.data;
    },
    error => {
      let mensaje = [];
      if (error.response.data.errors) {
        for (let i in error.response.data.errors) {
          mensaje.push(error.response.data.errors[i][0]);
        }
      } else {
        mensaje.push(error.response.data.message);
      }

      return {
        message: mensaje.join(' - '),
        success: false
      };
    }
  );
};

const motivos = () => {
  return axios.get(url_api + `jasmine-model/motivo-retiro`).then(
    response => {
      response.data.success = true;
      return response.data;
    },
    error => {
      if (!error.response) {
        return {
          message: 'El servidor no ha respondido correctamente.',
          success: false
        };
      }

      let mensaje = [];
      if (error.response.data.errors) {
        for (let i in error.response.data.errors) {
          mensaje.push(error.response.data.errors[i][0]);
        }
      } else {
        mensaje.push(error.response.data.message);
      }

      return {
        message: mensaje.join(' - '),
        success: false
      };
    }
  );
};

const sedes = () => {
  return axios.get(url_api + `jasmine-model/sedes`)
    .then(response => {
      response.data.success = true;
      return response.data;
    },
      error => {
        if (!error.response) {
          return {
            message: 'El servidor no ha respondido correctamente.',
            success: false
          };
        }

        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return {
          message: mensaje.join(' - '),
          success: false
        };
      }
    );
};

const webPages = () => {
  return axios.get(url_api + `jasmine-model/web-pages`).then(
    response => {
      response.data.success = true;
      return response.data;
    },
    error => {
      if (!error.response) {
        return {
          message: 'El servidor no ha respondido correctamente.',
          success: false
        };
      }

      let mensaje = [];
      if (error.response.data.errors) {
        for (let i in error.response.data.errors) {
          mensaje.push(error.response.data.errors[i][0]);
        }
      } else {
        mensaje.push(error.response.data.message);
      }

      return {
        message: mensaje.join(' - '),
        success: false
      };
    }
  );
};

const getWebPage = id => {
  return axios.get(url_api + `jasmine-model/${id}/webpage`).then(
    response => {
      response.data.success = true;
      return response.data;
    },
    error => {
      let mensaje = [];
      if (error.response.data.errors) {
        for (let i in error.response.data.errors) {
          mensaje.push(error.response.data.errors[i][0]);
        }
      } else {
        mensaje.push(error.response.data.message);
      }

      return {
        message: mensaje.join(' - '),
        success: false
      };
    }
  );
};

const estados = () => {
  return new Promise((resolve, reject) => {
    resolve({
      success: true,
      data: [
        {
          id: 1,
          nombre: 'Activo'
        },
        {
          id: 2,
          nombre: 'Inactivo'
        }
      ]
    });
  });
};

const importarModelos = file => {
  const formData = new FormData();
  formData.append('file', file.file);
  return axios
    .post(url_api + 'jasmine-model/importar-modelos', formData, {
      headers: { 'content-type': 'multipart/form-data' }
    })
    .then(
      response => {
        return response.data;
      },
      error => {
        return error.response.data;
      }
    );
};

const importarCuentas = file => {
  const formData = new FormData();
  formData.append('file', file.file);
  return axios
    .post(url_api + 'jasmine-model/importar-cuentas', formData, {
      headers: { 'content-type': 'multipart/form-data' }
    })
    .then(
      response => {
        return response.data;
      },
      error => {
        return error.response.data;
      }
    );
};

const importarUsuarios = file => {
  const formData = new FormData();
  formData.append('file', file.file);
  return axios
    .post(url_api + 'jasmine-model/importar-usuarios', formData, {
      headers: { 'content-type': 'multipart/form-data' }
    })
    .then(
      response => {
        return response.data;
      },
      error => {
        return error.response.data;
      }
    );
};

const adicionarCuenta = (id, cuenta) => {
  return axios.post(url_api + `jasmine-model/${id}/account`, cuenta).then(
    response => {
      response.data.success = true;
      return response.data;
    });
};

const cambiarPasswordCuenta = (cuenta_id, newPassword) => {
  return axios
    .post(url_api + `jasmine-model/account/${cuenta_id}/password`, {
      password: newPassword
    })
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return Promise.reject({
          message: mensaje.join(' - '),
          success: false
        });
      }
    );
};

const cambiarPrecioCuenta = (cuenta_id, newPrice) => {
  return axios
    .post(url_api + `jasmine-model/account/${cuenta_id}/price`, {
      price: newPrice
    })
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return Promise.reject({
          message: mensaje.join(' - '),
          success: false
        });
      }
    );
};

const cambiarUsernameCuenta = (cuenta_id, newUsername) => {
  return axios
    .post(url_api + `jasmine-model/account/${cuenta_id}/username`, {
      username: newUsername
    })
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return Promise.reject({
          message: mensaje.join(' - '),
          success: false
        });
      }
    );
};

const cambiarEstadoCuenta = (cuenta_id, newEstado) => {
  return axios
    .post(url_api + `jasmine-model/account/${cuenta_id}/estado`, {
      estado_id: newEstado.id
    })
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return Promise.reject({
          message: mensaje.join(' - '),
          success: false
        });
      }
    );
};

const exportarModelos = (relativeUrl, filename, params = {}) => {
  return axios
    .post(url_api + relativeUrl, params, {
      responseType: 'blob'
    })
    .then(response => {
      const type = response.headers['content-type'];
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: type })
      );
      const link = document.createElement('a');
      link.href = url;
      let definitiveFilename = '';
      const objFilename = getFilenameAndExtension(filename);
      const fecha = +new Date();
      definitiveFilename = objFilename.filename + '_' + fecha;

      link.setAttribute('download', definitiveFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return response;
    });
};

const getFilenameAndExtension = completeFilename => {
  const partsFileName = completeFilename.split('.');

  if (partsFileName.length === 0) {
    return {
      filename: 'archivo',
      extension: 'txt'
    };
  }
  if (partsFileName.length === 1) {
    return {
      filename: partsFileName[0],
      extension: 'txt'
    };
  }

  return {
    filename: partsFileName.slice(0, partsFileName.length - 2).join(),
    extension: partsFileName[partsFileName.length - 1]
  };
};

const movimientos = (modelo_id, fechaInicial, fechaFinal) => {
  return axios
    .get(
      url_api +
      `jasmine-model/${modelo_id}/movimientos?fecha_inicial=${fechaInicial}&fecha_final=${fechaFinal}`
    )
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        if (!error.response) {
          return {
            message: 'El servidor no ha respondido correctamente.',
            success: false
          };
        }

        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return {
          message: mensaje.join(' - '),
          success: false
        };
      }
    );
};

const enviarContratoFlirt = (id, contract) => {
  var data = new FormData();
  data.append('contrato', contract);
  return axios
    .post(url_api + `jasmine-model/account/${id}/contract`, data)
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return {
          message: mensaje.join(' - '),
          success: false
        };
      }
    );
};

const cargarImagenDoc = (id) => {
  return axios
    .get(url_api + `usuarios/${id}/documento-doblecara`)
    .then(
      response => {
        response.data.success = true;
        return response.data;
      },
      error => {
        let mensaje = [];
        if (error.response.data.errors) {
          for (let i in error.response.data.errors) {
            mensaje.push(error.response.data.errors[i][0]);
          }
        } else {
          mensaje.push(error.response.data.message);
        }

        return {
          message: mensaje.join(' - '),
          success: false
        };
      }
    );
};

const listarPlanillas = (userId, search, limit, page) => {
  let url = ``;
  if (userId) {
    url = `${url_api}modelos/${userId}/planillas-seguridad-social`;
  }
  if (!userId) {
    url = `${url_api}modelos/mis-planillas-seguridad-social`;
  }

  return axios.get(url, { params: search })
    .then(
      response => {
        response.data.success = true;
        return response.data;
      }
    );
}

const createOrUpdatePlanillaModelo = (data) => {
  var dataform = new FormData();
  dataform.append('archivo', data.archivo);
  dataform.append('anio', data.anio);
  dataform.append('mes', data.mes);
  const promise = data.id
    ? axios.patch(`${url_api}modelos/${data.usuario_id}/planillas-seguridad-social/${data.id}`, data)
    : axios.post(`${url_api}modelos/${data.usuario_id}/planillas-seguridad-social`, dataform);
  return promise.then(
    response => {
      response.data.success = true;
      return response.data;
    }
  )
};

const descargarPlanillaModelo = (userId, planillaId, filename) => {

  let relativeUrl = '';
  if (userId) {
    relativeUrl = `modelos/${userId}/planillas-seguridad-social/${planillaId}/descargar`;
  } else {
    relativeUrl = `modelos/mis-planillas-seguridad-social/${planillaId}/descargar`;
  }

  return axios
    .get(url_api + relativeUrl, { responseType: 'blob' })
    .then(response => {
      const type = response.headers['content-type'];
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: type })
      );
      const link = document.createElement('a');
      link.href = url;
      let definitiveFilename = '';
      const objFilename = getFilenameAndExtension(filename);
      const fecha = +new Date();
      definitiveFilename = objFilename.filename + '_' + fecha;

      link.setAttribute('download', definitiveFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return response;
    });
};

const deletePlanillaModelo = (userId, planillaId) => {
  let url = ``;
  if (userId) {
    url = `${url_api}modelos/${userId}/planillas-seguridad-social/${planillaId}`;
  } else {
    url = `${url_api}modelos/mis-planillas-seguridad-social/${planillaId}`;
  }

  return axios.delete(url).then(
    response => {
      response.data.success = true;
      return response.data;
    }
  )
};

const createOrUpdatePlanillaSiendoModelo = (data) => {
  var dataform = new FormData();
  dataform.append('archivo', data.archivo);
  dataform.append('anio', data.anio);
  dataform.append('mes', data.mes);
  const promise = data.id
    ? axios.patch(`${url_api}modelos/mis-planillas-seguridad-social/${data.id}`, data)
    : axios.post(`${url_api}modelos/mis-planillas-seguridad-social`, dataform);
  return promise.then(
    response => {
      response.data.success = true;
      return response.data;
    }
  )
};

const DownloadPlanilla = (planilla_id) => {
  return axios.get(`${url_api}modelos/mis-planillas-seguridad-social/${planilla_id}/descargar`)
    .then(response => {
      return Promise.resolve(response.data.data)
    });
};


const listModelsFTurns = (data) => {
  const params = data ? data : {};
  return axios.get(`${url_api}modelos/turnos`, { params })
    .then(response => {
      return Promise.resolve(response.data.data);
    })
}

const find = (id) => {
  return axios
    .get(url_api + `jasmine-model/${id}`)
    .then(
      response => {
        response.data.success = true;
        return response.data;
      }
    );
};

/**
 * Calcula la capacidaad de endeudamiento en dólares para el período actual
 * @param {number} identification Id de la modelo 
 * @returns Promise<{data: number, message: string}>
 */
const capacidadEndeudamiento = (identification) => {
  const periodo = periodoActual();
  var strFechaInicio = moment(new Date()).format('YYYY-MM-DD');
  var strFechaFin = moment(new Date()).format('YYYY-MM-DD');
  if (periodo !== undefined) {
    strFechaInicio = moment(periodo.inicio).format('YYYY-MM-DD');
    strFechaFin = moment(periodo.fin).format('YYYY-MM-DD');
  }

  return axios
    .get(url_api + `jasmine-model/${identification}/capacidad-endeudamiento?fecha_inicio=${strFechaInicio}&fecha_fin=${strFechaFin}`)
    .then(response => response.data);
};

/**
 * Retorna un arreglo de fechas en intervalos de de días definidos por 
 * intervaloDias entre fechaInicio(incluida) y fechaFin(incluida)
 * @param {Date} fechaInicio 
 * @param {Date} fechaFin 
 * @param {number} intervaloDias 
 * @returns 
 */
const obtenerFechas = (fechaInicio, fechaFin, intervaloDias) => {
  if (fechaInicio > fechaFin) {
    throw {
      message: 'La fecha de inicio no puede ser mayor a la fecha inicial ',
      fechaInicio,
      fechaFin
    }
  }
  const currentDate = new Date(fechaInicio.getTime());
  const fechas = [];
  while (currentDate < fechaFin) {
    fechas.push(new Date(currentDate.getTime()));
    currentDate.setDate(currentDate.getDate() + getPeriodDays(intervaloDias, currentDate));
  }
  fechas.push(new Date(currentDate.getTime()));
  return fechas;
};

/**
 * Devuelve un arreglo de rangos de fecha, iniciando desde fechaInicio,
 * en intervalos de intervaloDias días hasta que fechaFin esté
 * incluído en un intervalo.
 * 
 * @param {Date} fechaInicio 
 * @param {Date} fechaFin 
 * @param {number} intervaloDias 
 * @returns Array<{inicio: Date, fin: Date, text: string}>
 */
const obtenerRangos = (fechaInicio, fechaFin, intervaloDias = 1) => {
  const fechas = obtenerFechas(fechaInicio, fechaFin, intervaloDias);
  const rangos = [];
  // Recorremos las fechas
  for (let i = 1; i < fechas.length; i++) {
    // Colocamos en string el inicio y el fin
    const dateFin = new Date(fechas[i].getTime() - 24 * 60 * 60 * 60);

    const inicio = moment(fechas[i - 1]).format('YYYY-MM-DD');
    const fin = moment(dateFin).format('YYYY-MM-DD');
    // Lo adicionamos a los rangos
    rangos.push({
      inicio: fechas[i - 1],
      fin: dateFin,
      text: `${inicio} a ${fin}`
    });
  }
  return rangos;
};

/**
 * Devuelve el índice del array de rangos en el que se encuentra la fecha.
 * 
 * @param {Array<{inicio: Date, fin: Date, text: string}>} rangos Rangos de fechas. Ver "obtenerRangos"
 * @param {Date} fecha Fecha de la que se quiere saber en qué rango está.
 * @returns number Índice dentro del arreglo rangos.
 */
const indexOfRangeIn = (rangos, fecha) => {
  const index = rangos.findIndex((range) => {
    return fecha >= range.inicio && fecha <= range.fin;
  });
  return index;
};
let periodDays = undefined;
let firstDate = undefined;

const consultarDias = () => {
  periodDays = (PeriodObservable.getPeriodDays());
  firstDate = (PeriodObservable.getPeriodFirstDate());
  const unsubscribe = PeriodObservable.subscribe(() => {
    periodDays = (PeriodObservable.getPeriodDays());
    firstDate = (PeriodObservable.getPeriodFirstDate());
  });

  return unsubscribe;
};
consultarDias();

/**
 * Devuelve el listado de todos los períodos hasta fechaFin existentes.
 * @param {Date} fechaFin 
 * @returns Array<{inicio: Date, fin: Date, text: string}>
 */
const periodos = (fechaFin = new Date()) => {
  return obtenerRangos(new Date(firstDate), fechaFin, periodDays);
};

/**
 * Devuelve el rango del período en el que se encuentra actualmente,
 * @returns {inicio: Date, fin: Date, text: string}
 */
const periodoActual = () => {
  if ((firstDate === undefined || periodDays === undefined)) {
    return [];
  }
  const p = periodos();
  const index = indexOfRangeIn(p, new Date());
  return p[index];
};

const cargarAcuerdoCuentas = (id, files = {}) => {
  const formulario = new FormData();
  let hayDatosParaEnviar = false;
  ['acuerdo_cuentas'].forEach(name => {
    if (files[name]) {
      hayDatosParaEnviar = true;
      formulario.append(name, files[name]);
    }
  });

  if (!hayDatosParaEnviar) {
    return Promise.resolve({
      success: true,
      message: 'No habían datos para enviar.'
    });
  }
  return axios.post(`${url_api}jasmine-model/${id}/documento-pdf`, formulario)
    .then(response => response.data)
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const descagarAcuerdoCuentasFirmado = (id, identification, type) => {
  downloadGetFile({
    url: `${url_api}jasmine-model/${id}/documento-pdf/${type}`,
    filename: `${identification}.pdf`
  })
};

const descargarPlugin = () => {
  downloadGetFile({
    url: `${url_api}xara-plugin/download`,
    filename: `xara-plugin.zip`
  });
};

const ModeloService = {
  crearModelo,
  descargarPlugin,
  cargarAcuerdoCuentas,
  descagarAcuerdoCuentasFirmado,
  actualizarModelo,
  enviarImagenPerfil,
  obtener,
  inactivar,
  reactivar,
  activar,
  motivos,
  sedes,
  obtenerPassword,
  webPages,
  obtenerPasswordCorporativo,
  getWebPage,
  estados,
  importarModelos,
  importarUsuarios,
  importarCuentas,
  adicionarCuenta,
  cambiarPasswordCuenta,
  cambiarPrecioCuenta,
  cambiarEstadoCuenta,
  exportarModelos,
  cambiarUsernameCuenta,
  movimientos,
  cargarImagenDoc,
  enviarContratoFlirt,
  find,
  capacidadEndeudamiento,
  periodos,
  periodoActual,
  DownloadPlanilla,
  listarPlanillas,
  createOrUpdatePlanillaModelo,
  createOrUpdatePlanillaSiendoModelo,
  descargarPlanillaModelo,
  deletePlanillaModelo,
  listModelsFTurns,
};

export default ModeloService;
