import palette from "../palette";

export default {
  daySelected: {
    color: `${palette.text.primary}`,
    backgroundColor: '#6A67FF',
    '&:hover': {
      backgroundColor: '#6A67FF',
    }
  },
  day: {
    color: `${palette.text.secondary}`,
    fontWight: 'bold',
  },
};