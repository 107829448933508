import axios from 'axios';
import { getBase64 } from 'src/utils/FileUtils';
import { getMessageFromErrorResponse } from 'src/utils/getMessageFromErrorResponse';
import url_api from '../constants/apiJasmine';
import AuthService from './AuthService';

const addExtraPoints = ({ competition_id, modelo_id, extra_points }) => {
  // Agregar puntos extra:
  return axios
    .post(
      `${url_api}competition/${competition_id}/model/${modelo_id}/add-points`,
      { extra_points }
    )
    .then(response => response.data)
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const banModel = (competition_id, modelo_id) => {
  // Agregar puntos extra:
  return axios
    .post(
      `${url_api}competition/${competition_id}/model/${modelo_id}/ban`
    )
    .then(response => response.data)
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const consultarConcursos = (page, limit, sede_id) => {
  // Consultar Concursos:

  if (AuthService.can('super_sede')) {
    return axios
      .get(`${url_api}competition?page=${page}&limit=${limit}`, {
        params: {
          sede_id: sede_id === -1 ? null : sede_id
        }
      })
      .then(response => response.data)
      .catch(errors => Promise.reject(getMessageFromErrorResponse(errors)));
  } else {
    return axios
      .get(`${url_api}competition?page=${page}&limit=${limit}`)
      .then(response => response.data)
      .catch(errors => Promise.reject(getMessageFromErrorResponse(errors)));
  }

};

const crearConcurso = competition => {

  const promises = [competition.image_header, competition.image_center, competition.image_footer]
    .map(img => (img && typeof img === 'object') ? getBase64(img) : Promise.resolve(null));

  return Promise.all(promises)
    .then(response => {
      return axios
        .post(`${url_api}competition`, {
          ...competition,
          image_header: response[0],
          image_center: response[1],
          image_footer: response[2],
        });
    })
    .then(response => response.data);
};

const editarConcurso = competition => {

  // Promesas cuya imagen sea un File, y no un string
  const promises = [competition.image_header, competition.image_center, competition.image_footer]
    .map(img => (img && typeof img === 'object') ? getBase64(img) : Promise.resolve(null));

  return Promise
    .all(promises)
    .then(response => {
      return axios
        .patch(`${url_api}competition/${competition.id}`,
          {
            ...competition,
            image_header: response[0],
            image_center: response[1],
            image_footer: response[2],
          }
        );
    })
    .then(response => response.data);
};

const verConcurso = competition_id => {
  return axios.get(`${url_api}competition/${competition_id}`)
    .then(response => response.data)
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const getPodium = () => {
  return axios.get(`${url_api}hall-of-fame`)
    .then(response => response.data)
    .catch(error => Promise.reject(getMessageFromErrorResponse(error)));
};

const ConcursosServices = {
  addExtraPoints,
  consultarConcursos,
  crearConcurso,
  editarConcurso,
  verConcurso,
  banModel,
  getPodium,
};

export default ConcursosServices;
