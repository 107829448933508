import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Card, CardActions, CardContent, CardHeader, Container, Dialog, Grid, IconButton, Modal, Toolbar, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    color: 'white'
  },
  toolbar: {
    justifyContent: 'space-between'
  }
}));

/**
 * Despliega un Modal
 * @param {any} param0 
 * @returns 
 */
const ModalForm = ({ open, onClose, content, actions, title, fullScreen }) => {

  const classes = useStyles();

  const alertRef = useRef(null);

  const executeScroll = () => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView();
    }
  };

  useEffect(executeScroll, []);

  /**
   * Se consulta si el modal debe ser en pantalla completa o no usando fullScreen (Boolean)
   */
  if (fullScreen) {
    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <AppBar style={{ position: 'sticky', width: '100%' }}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <IconButton onClick={onClose}            >
              <Cancel
                className={classes.title}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm">
          {content}
        </Container>
      </Dialog>
    );
  }

  /**
   * En caso contrario, se muestra el modal sin cubrir toda la pantalla
   */
  return (
    <Modal open={open} onClose={onClose}>
      <Card className={classes.root}>
        <CardHeader title={
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography variant="h5">
              {title}
            </Typography>
            <IconButton style={{ padding: 0, color: '#ffffff' }} onClick={() => { onClose() }}>
              <Cancel />
            </IconButton>
          </Grid>
        } />
        <CardContent>
          {content}
        </CardContent>
        <CardActions className={classes.actions}>
          {actions}
        </CardActions>
      </Card>
    </Modal>
  );
};

ModalForm.propTypes = {
  /**
   * Indica si abre o cierra
   */
  open: PropTypes.bool,
  /**
   * Se lanza cuando se cierra el formulario
   */
  onClose: PropTypes.func,
  /**
   * Componente para mostrar. Puede ser un formulario o un texto
   */
  content: PropTypes.element,
  /**
   * Aquí iría el footer del modal. Deberían ser botones.
   */
  actions: PropTypes.element,
  /**
   * Un string con el título de la ventana.
   */
  title: PropTypes.string,
};

ModalForm.propsDefault = {
  open: false,
  onClose: (ev) => console.log(ev),
  content: <div>...</div>,
  actions: <div>...</div>,
  title: '',
  fullScreen: false,
};

export default ModalForm;