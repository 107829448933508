import React, { useEffect, useState } from 'react';
import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ListIcon from '@material-ui/icons/List';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ShowChart  from '@material-ui/icons/ShowChart';

import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HistoryIcon from '@material-ui/icons/History';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CommentIcon from '@material-ui/icons/Comment';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Label from 'src/components/Label';
import {
  Assignment,
  DesktopMac,
  HowToReg,
  Language,
  LocalShipping,
  EmojiEvents,
  VpnKey,
  MultilineChart,
  DonutLarge,
  SchoolOutlined,
  Book,
  AccountTree,
  Settings,
  Dns,
  FormatListNumbered,
  LocalAtm,
  AccountBalance,
  AccountBalanceWallet,
  OpenInBrowser,
  Receipt,
  Fingerprint,
  DateRange,
  PermDataSetting,
  Email,
  ChromeReaderMode,
} from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ContactsIcon from '@material-ui/icons/Contacts';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import VideocamIcon from '@material-ui/icons/Videocam';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PermissionObservable from 'src/observers/PermissionObservable';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ListAlt from '@material-ui/icons/ListAlt';
import AuthService from 'src/services/AuthService';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import PaymentIcon from '@material-ui/icons/Payment';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LiveJasminIcon from '../../components/Icons/LiveJasminIcon';

const getTreeWithPermission = (permissions, role) => {
  let user = AuthService.user();
  if (user) {
    if (typeof user.modelo === 'undefined') {
      AuthService.refreshUser().then(() => {
        window.location.reload();
      });
    }
  }

  if (!permissions) {
    return [];
  }

  const can = p => {
    return permissions.indexOf(p) !== -1;
  };

  return [
    {
      subheader: '',
      permiso: true,
      items: [
        {
          permiso: true,
          title: 'Mi Cuenta',
          href: '/mi-cuenta',
          icon: AccountCircleIcon,
          items: [
            {
              permiso: true,
              title: 'Mi Perfil',
              href: '/mi-perfil',
              icon: AccountCircleIcon,
            },
            {
              permiso: true,
              title: 'Mis descuentos',
              href: '/mi-cuenta/mis-descuentos',
              icon: MoneyOffIcon
            },
            {
              permiso: can('ver_mis_pagos'),
              title: 'Mis pagos',
              href: '/mi-cuenta/mis-pagos',
              icon: PaymentIcon
            },
            {
              permiso: can('descargar_mi_constancia_ingresos') && role === 'modelo',
              title: 'Mis constancias',
              href: '/mi-cuenta/mis-constancias',
              icon: ChromeReaderMode
            },
            {
              permiso: can('priorizar_rooms') && role === 'modelo',
              title: 'Priorización de rooms',
              href: '/priorizacion-rooms/',
              icon: PlaylistAddRoundedIcon
            },
          ]
        },
        // Desde aquí los módulos que son sólo para modelos
        {
          permiso: can('ver_modulo_concursos'),
          title: 'Concursos',
          href: '/concursos',
          icon: EmojiEvents
        },
        {
          permiso: can('listar_mis_planillas') && role === 'modelo',
          title: 'Planilla Seguridad Social',
          href: '/modelos/mis-planillas-seguridad-social',
          icon: ListAltIcon
        },
        {
          permiso: can('ver_modulo_reportes') && role === 'modelo',
          title: 'Reporte Ganancias',
          href: '/reportes/ganancias/modelo',
          icon: LocalAtmIcon
        },
        // Módulos sólo para administrativos
        {
          permiso: can('descargar_encuestas'),
          title: 'Encuestas Generales',
          href: '/encuestas-generales',
          icon: AttachMoneyIcon
        },
        {
          permiso: can('ver_capacidad_endeudamiento'),
          title: 'Capacidad de Endeudamiento',
          href: '/modelos/capacidad-endeudamiento',
          icon: AttachMoneyIcon
        },
        {
          permiso: can('ver_modulo_listado_descuentos'),
          title: 'Listado Descuentos',
          href: '/listado-descuentos',
          icon: MoneyOffIcon
        },
        {
          permiso: can('ver_modulo_gestion_noticias'),
          title: 'Noticias',
          href: '/noticias',
          icon: LibraryBooksIcon,
        },
        {
          permiso: can('ver_modulo_modelos'),
          title: 'Modelos',
          href: '/modelos',
          icon: FavoriteIcon,
          items: [
            {
              permiso: can('ver_modulo_modelos') && can('listar_modelos'),
              title: 'Datos Modelos',
              icon: AccountBoxOutlinedIcon,
              href: '/modelos'
            },
            {
              permiso: can('ver_cuentas_bloquedas_login'),
              title: 'Bloquedas',
              href: '/modelos/cuentas-bloqueadas-por-login',
              icon: ErrorIcon
            }
          ]
        },
        {
          permiso: can('ver_modulo_usuarios'),
          title: 'Usuarios',
          href: '/  ',
          icon: EmojiPeopleIcon,
          items: [
            {
              permiso: can('listar_usuarios'),
              title: 'Datos Usuarios',
              icon: AccountCircleIcon,
              href: '/usuarios'
            },
            {
              permiso: can('administrar_roles'),
              title: 'Permisos',
              icon: VpnKey,
              href: '/permisos'
            }
          ]
        },
        {
          permiso: can('ver_modulo_reportes') && role !== 'modelo',
          title: 'Reportes',
          href: '/reportes',
          icon: AssessmentIcon,
          items: [
            {
              permiso: can('ver_reportes_ganancias'),
              title: 'Reporte de ganancias',
              href: '/reportes/ganancias',
              icon: MonetizationOnIcon
            },
            {
              permiso: can('ver_reportes_personalizados'),
              title: 'Reporte de ganancias personalizadas',
              href: '/reportes/ganancias-personalizadas',
              icon: LocalAtmIcon,
            },
            {
              permiso: can('ver_modulo_reportes') && role !== 'modelo',
              title: 'Reporte anual',
              href: '/reportes/reportes-anuales',
              icon: DateRangeIcon,
            }
          ]
        },
        {
          permiso: can('ver_modulo_jasmin'),
          title: 'Jasmin',
          href: '/jasmin',
          icon: LiveJasminIcon,
          items: [
            {
              permiso: can('ver_reporte_mensajes_modelo'),
              title: 'Reportes Tiempo de Mensajes Modelo',
              href: '/tiempo/mensajes/modelo',
              icon: ScheduleIcon
            },
            {
              permiso: can('ver_modulo_cuentas_jasmin'),
              title: 'Cuentas Jasmin',
              href: '/transmision/cuentas-jasmin',
              icon: Language
            },
            {
              permiso: can('ver_modulo_mensajes_jasmin'),
              title: 'Mensajes Jasmin',
              href: '/transmision/mensajes-automaticos',
              icon: CommentIcon
            }
          ]
        },
        {
          title: 'Dashboards',
          href: '/dashboards',
          icon: DashboardIcon,
          items: [
            {
              title: 'Default',
              href: '/dashboards/default'
            },
            {
              title: 'Analytics',
              href: '/dashboards/analytics'
            }
          ]
        },
        {
          title: 'Indicadores',
          href: '/indicadores',
          icon: MultilineChart,
          permiso: can('ver_reporte_captaciones'),
          items: [
            {
              permiso: can('indicadores_de_reclutamiento'),
              title: 'Indicadores de reclutamiento',
              href: '/indicadores/reclutamiento',
              icon: GroupAddIcon
            },
            {
              permiso: can('indicadores_de_captaciones'),
              title: 'Indicadores de mercadeo',
              href: '/indicadores/mercadeo',
              icon: PhotoAlbumIcon
            },
            {
              permiso: can('indicadores_financieros'),
              title: 'Indicadores de TH',
              href: '/indicadores/talento-humano',
              icon: AccessibilityIcon
            },
            {
              permiso: can('indicadores_de_operaciones'),
              title: 'Indicadores de operaciones',
              href: '/indicadores/operaciones',
              icon: DonutLarge
            }
          ]
        },
        {
          title: 'Proveedores',
          href: '/proveedores',
          icon: LocalShipping,
          permiso: can('listar_proveedores')
        },
        {
          title: 'Escuela de Modelos',
          href: '/escuela-modelos/escuela',
          icon: SchoolOutlined,
          permiso: can('ver_modulo_escuela_modelos'),
          items: [
            {
              permiso: can('ver_modulo_escuela_modelos'),
              title: 'Inicio',
              href: '/escuela-modelos/escuela',
              icon: AccountBalanceIcon
            },
            {
              permiso: can('ver_modulo_escuela_modelos'),
              title: 'Cursos',
              href: '/escuela-modelos/cursos',
              icon: Book
            },
            {
              permiso: can('ver_modulo_escuela_modelos'),
              title: 'Programación',
              href: '/escuela-modelos/programacion',
              icon: AccountTree
            },
            {
              permiso: can('ver_modulo_escuela_modelos'),
              title: 'Formularios',
              href: '/escuela-modelos/formularios',
              icon: ListAlt
            }
          ]
        },
        {
          permiso: can('ver_modulo_th'),
          title: 'Talento humano',
          href: '/talento-humano',
          icon: AccessibilityIcon,
          items: [
            {
              permiso: can('ver_disponibilidad_entrevistas'),
              title: 'Disponibilidad para entrevistas',
              href: '/talento-humano/disponibilidad-th',
              icon: CalendarTodayIcon
            }
          ]
        },
        {
          title: 'Pago a Modelos',
          href: '/liquidacion-pagos',
          icon: MonetizationOn,
          permiso: can('ver_modulo_nomina'),
          items: [
            {
              permiso: can('listar_liquidaciones'),
              title: 'Liquidación del Pago',
              href: '/liquidacion-pagos',
              icon: AccountBalanceWallet
            },
            {
              permiso: can('listar_info_fiscal'),
              title: 'Información Fiscal',
              href: '/informacion-fiscal',
              icon: AccountBalance
            }
          ]
        },
        {
          permiso: can('ver_modulo_transmision'),
          title: 'Transmisión',
          href: '/transmision',
          icon: DesktopMac,
          items: [
            {
              permiso: can('ver_reporte_estados'),
              title: 'Reporte de estados en línea',
              href: '/transmision/reporte-estados',
              icon: Language
            },
            {
              permiso: can('ver_asistencia_modelos'),
              title: 'Asistencia de Modelos',
              href: '/transmision/asistencias',
              icon: AssignmentTurnedIn
            },
            {
              permiso: can('recepcion_rooms'),
              title: 'Recepción de Rooms',
              href: '/recepcion-rooms/rooms',
              icon: AssignmentTurnedInIcon
            },
            {
              permiso: can('historico_room'),
              title: 'Histórico de Rooms',
              href: '/recepcion-rooms/rooms/history',
              icon: HistoryIcon
            },
            {
              permiso: can('listar_periodos_asignacion_rooms'),
              title: 'Planificación Rooms',
              href: '/periodos/',
              icon: EventNoteIcon
            },
            {
              permiso: can('ver_entrenamiento_modelos'),
              title: 'Entrenamiento de modelos',
              href: '/entrenamiento-modelos',
              icon: AssignmentTurnedInIcon
            },
            {
              permiso: can('ver_estadisticas_entrenamiento'),
              title: 'Estadisticas de entrenamiento',
              href: '/estadisticas-entrenamiento',
              icon: ShowChart 
            }
          ]
        },
        {
          permiso: can('ver_modulo_encuestas'),
          title: 'Registro de Ingreso',
          href: '/encuestas',
          icon: Fingerprint
        },
        {
          title: 'Autorizaciones',
          href: '/autorizaciones',
          icon: AssignmentTurnedInIcon,
          permiso: can('ver_modulo_gestion_autorizaciones')
        },
        {
          permiso: can('ver_modulo_captaciones'),
          title: 'Reclutamiento',
          href: '/captaciones',
          icon: Assignment,
          items: [
            {
              permiso: can('ver_modulo_actividades'),
              title: 'Mis actividades',
              href: '/captaciones/mis-actividades',
              icon: HowToReg
            },
            {
              permiso: can('ver_modulo_contactos'),
              title: 'Contactos',
              href: '/captaciones/contactos',
              icon: ContactsIcon
            },
            {
              permiso: can('ver_modulo_calendario_sesion_fotos'),
              title: 'Calendario de sesión de fotos',
              href: '/mercadeo/calendario-sesion-fotos',
              icon: CameraAltIcon
            },
            {
              permiso: can('ver_modulo_calendario_pruebas_transmision'),
              title: 'Calendario de Audición',
              href: '/transmision/calendario-pruebas-transmision',
              icon: VideocamIcon
            },
            {
              permiso: can('ver_modulo_calendario_entrevistas'),
              title: 'Calendario de entrevistas',
              href: '/talento-humano/calendario-entrevistas',
              icon: AssignmentTurnedInIcon
            },
            {
              permiso: can('ver_modulo_lista_procesos'),
              title: 'Lista de procesos',
              href: '/captaciones/lista-procesos',
              icon: ListAltIcon
            },
            {
              permiso: can('ver_modulo_capacitaciones'),
              title: 'Inducción Inicial',
              href: '/captaciones/asignar-capacitaciones',
              icon: SupervisedUserCircleIcon
            }
          ]
        },
        {
          permiso: can('ver_modulo_configuracion'),
          title: 'Configuracion',
          href: '/configuracion',
          icon: Settings,
          items: [
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'General',
              href: '/configuracion/general',
              icon: Dns
            },
            {
              permiso: can('listar_rooms'),
              title: 'Datos Rooms',
              href: '/configuracion/rooms',
              icon: MeetingRoomIcon
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Sedes',
              href: '/sedes',
              icon: LocationCityIcon,
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Períodos',
              href: '/configuracion/periodos',
              icon: DateRange
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Turnos',
              href: '/configuracion/turnos',
              icon: FormatListNumbered
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Formulario de prueba de transmisión',
              href: '/formulario-prueba-transmision/',
              icon: PermDataSetting,
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Formulario de prueba de psicología',
              href: '/formulario-prueba-psicologia/',
              icon: PermDataSetting,
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Categoría descuentos',
              href: '/configuracion/categoria-descuentos',
              icon: Receipt
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Configurar plataformas',
              href: '/configuracion/plataformas',
              icon: OpenInBrowser
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Configurar pagos',
              href: '/configuracion/pagos',
              icon: LocalAtm
            },
            {
              permiso: can('ver_modulo_configuracion'),
              title: 'Envío de notificaciones',
              href: '/configuracion/notificar-roles',
              icon: Email
            },
            {
              permiso: false,
              title: 'Metas',
              href: '/configuracion/metas',
              icon: LocalAtm
            },
            {
              permiso: false,
              title: 'Tipos de cuentas',
              href: '/configuracion/tipo-de-caja-contable',
              icon: AccountBalanceWalletIcon
            },
          ]
        },
        {
          permiso: false,
          title: 'Mercadeo',
          href: '/mercadeo',
          icon: EmojiObjectsIcon,
          items: [
            {
              permiso: false,
              title: 'Disponibilidad de sesión de fotos',
              href: '/mercadeo/disponibilidad-sesion-fotos',
              icon: CalendarTodayIcon
            }
          ]
        },
        {
          title: 'Overview',
          href: '/overview',
          icon: HomeIcon
        },
        {
          title: 'Management',
          href: '/management',
          icon: BarChartIcon,
          items: [
            {
              title: 'Customers',
              href: '/management/customers'
            },
            {
              title: 'Customer Details',
              href: '/management/customers/1/summary'
            },
            {
              title: 'Projects',
              href: '/management/projects'
            },
            {
              title: 'Orders',
              href: '/management/orders'
            },
            {
              title: 'Order Details',
              href: '/management/orders/1'
            }
          ]
        },
        {
          title: 'Social Feed',
          href: '/social-feed',
          icon: PeopleIcon
        },
        {
          title: 'Profile',
          href: '/profile',
          icon: PersonIcon,
          items: [
            {
              title: 'Timeline',
              href: '/profile/1/timeline'
            },
            {
              title: 'Connections',
              href: '/profile/1/connections'
            },
            {
              title: 'Projects',
              href: '/profile/1/projects'
            },
            {
              title: 'Reviews',
              href: '/profile/1/reviews'
            }
          ]
        },
        {
          title: 'Project',
          href: '/projects',
          icon: FolderIcon,
          items: [
            {
              title: 'Browse',
              href: '/projects'
            },
            {
              title: 'Create',
              href: '/projects/create'
            },
            {
              title: 'Overview',
              href: '/projects/1/overview'
            },
            {
              title: 'Files',
              href: '/projects/1/files'
            },
            {
              title: 'Activity',
              href: '/projects/1/activity'
            },
            {
              title: 'Subscribers',
              href: '/projects/1/subscribers'
            }
          ]
        },
        {
          title: 'Invoice',
          href: '/invoices/1',
          icon: ReceiptIcon
        },
        {
          title: 'Kanban Board',
          href: '/kanban-board',
          icon: ListAltIcon
        },
        {
          title: 'Mail',
          href: '/mail',
          icon: MailIcon,
          label: () => (
            <Label color={colors.red[500]} shape="rounded">
              2
            </Label>
          )
        },
        {
          title: 'Chat',
          href: '/chat',
          icon: ChatIcon,
          label: () => (
            <Label color={colors.red[500]} shape="rounded">
              4
            </Label>
          )
        },
        {
          title: 'Calendar',
          href: '/calendar',
          icon: CalendarTodayIcon,
          label: () => <Label color={colors.green[500]}>New</Label>
        },
        {
          title: 'Settings',
          href: '/settings',
          icon: SettingsIcon,
          items: [
            {
              title: 'General',
              href: '/settings/general'
            },
            {
              title: 'Subscription',
              href: '/settings/subscription'
            },
            {
              title: 'Notifications',
              href: '/settings/notifications'
            },
            {
              title: 'Security',
              href: '/settings/security'
            }
          ]
        },
        {
          title: 'Authentication',
          href: '/auth',
          icon: LockOpenIcon,
          items: [
            {
              title: 'Login',
              href: '/auth/login'
            },
            {
              title: 'Register',
              href: '/auth/register'
            }
          ]
        },
        {
          title: 'Errors',
          href: '/errors',
          icon: ErrorIcon,
          items: [
            {
              title: 'Error 401',
              href: '/errors/error-401'
            },
            {
              title: 'Error 404',
              href: '/errors/error-404'
            },
            {
              title: 'Error 500',
              href: '/errors/error-500'
            }
          ]
        },
      ]
    },
    {
      permiso: false,
      subheader: 'Support',
      items: [
        {
          title: 'Components',
          href: '/components',
          icon: ViewConfigIcon,
          items: [
            {
              title: 'Buttons',
              href: '/components/buttons'
            },
            {
              title: 'Cards',
              href: '/components/cards'
            },
            {
              title: 'Chips',
              href: '/components/chips'
            },
            {
              title: 'Lists',
              href: '/components/lists'
            },
            {
              title: 'Forms',
              href: '/components/forms'
            },
            {
              title: 'Modals',
              href: '/components/modals'
            },
            {
              title: 'Typography',
              href: '/components/typography'
            }
          ]
        },
        {
          title: 'Presentation',
          href: '/presentation',
          icon: PresentToAllIcon
        },
        {
          title: 'Getting started',
          href: '/getting-started',
          icon: CodeIcon
        },
        {
          title: 'Changelog',
          href: '/changelog',
          icon: ListIcon,
          label: () => <Label color={colors.blue['500']}>v1.3.0</Label>
        }
      ]
    }
  ];
};

const fnFilter = item => {
  return !!item.permiso;
};

function useNavConfig() {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const permisionObservable = PermissionObservable.subscribe(
      (oldPermissions, permissions) => {
        setPermissions(permissions);
      }
    );
    return () => {
      permisionObservable.dispose();
    };
  }, []);

  const treeWithPermission = getTreeWithPermission(
    permissions,
    AuthService.user()?.role.toLowerCase()
  );

  let newTree = treeWithPermission.filter(fnFilter);

  for (let i = 0; i < newTree.length; i++) {
    if (newTree[i].items) {
      newTree[i].items = newTree[i].items.filter(fnFilter);

      for (let j = 0; j < newTree[i].items.length; j++) {
        if (newTree[i].items[j].items) {
          newTree[i].items[j].items = newTree[i].items[j].items.filter(
            fnFilter
          );
        }
      }
    }
  }

  return newTree;
}

export default useNavConfig;
