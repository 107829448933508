import palette from "../palette";

export default {
  root: {
    '&:focus': {
    backgroundColor: palette.background.default,
    color: palette.primary.contrastText,
    }
  },
  //se calcula el ancho del topbar menos el ancho del navbar
  monthSelected: {
    backgroundColor: palette.background.default,
    color: palette.primary.contrastText,
  },
};