import axios from 'axios';
import url_api from 'src/constants/apiJasmine';

/**
 *  Listar Notificaciones
 * @returns Notificaciones
 */
// CON SERVIDOR:
const listarNotificaciones = (notificacionId, limite) => {

  if (notificacionId === null) {
    return axios
      .get(`${url_api}notificaciones?limit=${limite}`)
      .then(response => {
        return response.data;
      });
  }
  else {
    return axios
      .get(`${url_api}notificaciones?limit=${limite}&last_notification_id=${notificacionId}`)
      .then(response => {
        return response.data;
      });
  }
};

/***
 * Marcar todas las notificaciones como Leídas
 */
// CON SERVIDOR:
const leerTodas = () => {
  return axios.post(`${url_api}notificaciones/marcacion-masiva`);
};

/**
 * Marcar notificación como leída
 */
// CON SERVIDOR:
const leerNotificacion = notificacionId => {
  return axios.post(`${url_api}notificaciones/${notificacionId}/marcar-leida`);
};

/**
 * Consultar Buzón
 */
// CON SERVIDOR:
const consultarBuzon = () => {
  return axios.get(`${url_api}notificaciones-buzon`).then(response => {
    //updateNotifications(response.headers)
    return response.data;
  });
};

/**
 * No Leídas
 */
// CON SERVIDOR:
const consultarNoLeidas = async (date, limit = 5) => {
  const response = await axios.get(`${url_api}notificaciones-no-leidas?fecha=${date}&limit=${limit}`);
  return response.data;
};

const NotificationsService = {
  listarNotificaciones,
  leerTodas,
  leerNotificacion,
  consultarBuzon,
  consultarNoLeidas,
};

export default NotificationsService;
