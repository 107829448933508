import palette from '../palette';

export default {
  root: {
    color: palette.text.secondary,
    '& .MuiTypography-root': {
      color: palette.text.secondary,
    },
  },
};
