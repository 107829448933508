import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';
import PrivateRoute from './views/PrivateRoute';
import Register from './views/Register';
import ReporteInicial from './views/Reportes/ReporteInicial';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/presentation" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/examen/:hash',
        exact: true,
        component: lazy(() => import('src/views/Escuela/Formularios/Resolver'))
      },
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login/LoginPage'))
      },
      {
        path: '/auth/reset-password/:token/:email',
        exact: true,
        component: lazy(() => import('src/views/Login/ResetPasswordPage'))
      },
      {
        path: '/auth/recovery-password',
        exact: true,
        component: lazy(() => import('src/views/Login/RecoveryPasswordPage'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register'))
      },
      {
        path: '/auth/protegida',
        exact: true,
        component: () => (
          <PrivateRoute component={<Register></Register>}></PrivateRoute>
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-403',
        exact: true,
        component: lazy(() => import('src/views/Error403'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/presentation',
        exact: true,
        component: () => (
          <PrivateRoute
            component={<PresentationView></PresentationView>}
          ></PrivateRoute>
        )
      },
      {
        path: '/modelos',
        exact: true,
        component: lazy(() => import('src/views/Modelos/Lista'))
      },
      {
        path: '/modelos/turnos-modelos',
        exact: true,
        component: lazy(() => import('src/views/Modelos/ModelosXTurnos'))
      },
      {
        path: '/modelos/:modelo_id/movimientos',
        exact: true,
        component: lazy(() => import('src/views/Movimientos/Lista'))
      },
      {
        path: '/modelos/:usuario_id/planillas-seguridad-social',
        exact: true,
        component: lazy(() => import('src/views/Modelos/Planilla'))
      },
      {
        path: '/modelos/mis-planillas-seguridad-social',
        exact: true,
        component: lazy(() => import('src/views/Modelos/Planilla'))
      },
      {
        path: '/modelos/cuentas-bloqueadas-por-login',
        exact: true,
        component: lazy(() => import('src/views/Modelos/CuentasBloqueadas'))
      },
      {
        path: '/usuarios',
        exact: true,
        component: lazy(() => import('src/views/Usuarios2/Lista'))
      },
      {
        path: '/permisos',
        exact: true,
        component: lazy(() => import('src/views/PermisosNuevos'))
      },
      {
        path: '/noticias',
        exact: true,
        component: lazy(() => import('src/views/Noticias'))
      },
      {
        path: '/documentacion',
        exact: true,
        component: lazy(() => import('src/views/Documentacion/Lista'))
      },
      {
        path: '/indicadores/reclutamiento',
        exact: true,
        component: lazy(() => import('src/views/Indicadores/IndicadorReclutamiento'))
      },
      {
        path: '/indicadores/mercadeo',
        exact: true,
        component: lazy(() => import('src/views/Indicadores/IndicadorMercadeo'))
      },
      {
        path: '/indicadores/operaciones',
        exact: true,
        component: lazy(() =>
          import('src/views/Indicadores/IndicadorOperaciones')
        )
      },
      {
        path: '/indicadores/talento-humano',
        exact: true,
        component: lazy(() =>
          import('src/views/Indicadores/IndicadorTalentoHumano')
        )
      },
      {
        path: '/recepcion-rooms/rooms',
        exact: true,
        component: lazy(() => import('src/views/Rooms/List'))
      },
      {
        path: '/transmision/cuentas-jasmin',
        exact: true,
        component: lazy(() => import('src/views/Transmision/CuentasJasmin'))
      },
      {
        path: '/transmision/miembros/:cuentaJasminId',
        exact: true,
        component: lazy(() => import('src/views/Transmision/Miembros'))
      },
      {
        path: '/transmision/mensajes-automaticos',
        exact: true,
        component: lazy(() =>
          import('src/views/Transmision/MensajesAutomaticos')
        )
      },
      {
        path: '/transmision/mensajes-automaticos/conversacion/:conversacionId',
        exact: true,
        component: lazy(() =>
          import('src/views/Transmision/ConversacionJasmin')
        )
      },
      {
        path: '/transmision/mensajes-automaticos/conversacion',
        exact: true,
        component: lazy(() =>
          import('src/views/Transmision/ConversacionJasmin')
        )
      },
      {
        path: '/modelos/capacidad-endeudamiento',
        exact: true,
        component: lazy(() =>
          import('src/views/Modelos/CapacidadEndeudamiento')
        )
      },
      {
        path: '/recepcion-rooms/rooms/history',
        exact: true,
        component: lazy(() => import('src/views/Rooms/History'))
      },
      {
        path: '/priorizacion-rooms/',
        exact: true,
        component: lazy(() => import('src/views/Rooms/Priorization'))
      },
      {
        path: '/periodos',
        exact: true,
        component: lazy(() => import('src/views/Rooms/Periods'))
      },
      {
        path: '/entrenamiento-modelos',
        exact: true,
        component: lazy(() => import('src/views/AssignTrain'))
      },
      {
        path: '/estadisticas-entrenamiento',
        exact: true,
        component: lazy(() => import('src/views/StaticsTrain'))
      },
      {
        path: '/ejecucion/:planeacionId/calendar',
        exact: true,
        component: lazy(() => import('src/views/CalendarMonth'))
      },
      {
        path: '/configuracion/rooms',
        exact: true,
        component: lazy(() => import('src/views/Rooms/ConfigRoom'))
      },
      {
        path: '/planeacion-rooms/:planeacionId/:sedeId',
        exact: true,
        component: lazy(() => import('src/views/Rooms/CalendarTest'))
      },
      {
        path: '/ejecucion/:planeacionId',
        exact: true,
        component: lazy(() => import('src/views/PlanningExecuted'))
      },
      {
        path: '/datos-turnos',
        exact: true,
        component: lazy(() => import('src/views/Rooms/CrudTurn'))
      },
      {
        path: '/formulario-prueba-transmision',
        exact: true,
        component: lazy(() => import('src/views/Configuracion/FormularioPruebaTransmision'))
      },
      {
        path: '/formulario-prueba-psicologia',
        exact: true,
        component: lazy(() => import('src/views/Configuracion/FormularioPsicologia'))
      },
      {
        path: '/reportes/historico',
        exact: true,
        component: () => (
          <PrivateRoute
            component={<ReporteInicial></ReporteInicial>}
          ></PrivateRoute>
        )
      },
      {
        path: '/reportes/ganancias',
        exact: true,
        component: lazy(() => import('src/views/Reportes/ReporteCompleto'))
      },
      {
        path: '/reportes/ganancias-personalizadas',
        exact: true,
        component: lazy(() => import('src/views/Reportes/ReporteCompleto/ReportePersonalizado'))
      },
      {
        path: '/reportes/reportes-anuales',
        exact: true,
        component: lazy(() => import('src/views/Reportes/ReporteCompleto/ReporteAnual'))
      },
      {
        path: '/reportes/ganancias/modelo',
        exact: true,
        component: lazy(() => import('src/views/Reportes/ReporteModelo'))
      },
      {
        path: '/tiempo/mensajes/modelo',
        exact: true,
        component: lazy(() => import('src/views/TiempoMensajeModelos'))
      },
      {
        path: '/proveedores',
        exact: true,
        component: lazy(() => import('src/views/Proveedores'))
      },
      {
        path: '/autorizaciones',
        exact: true,
        component: lazy(() => import('src/views/Autorizaciones'))
      },
      {
        path: '/captaciones/mis-actividades',
        exact: true,
        component: lazy(() => import('src/views/Captaciones/Actividades'))
      },
      {
        path: '/captaciones/capacitaciones',
        exact: true,
        component: lazy(() => import('src/views/Captaciones/Capacitaciones'))
      },
      {
        path: '/captaciones/calificacion-psicologica',
        exact: true,
        component: lazy(() =>
          import('src/views/Captaciones/Evaluaciones/FormularioPsicologia')
        )
      },
      {
        path: '/captaciones/prueba-transmision',
        exact: true,
        component: lazy(() => import('src/views/Captaciones/PruebaTransmision'))
      },
      {
        path: '/captaciones/asignar-prueba-transmision',
        exact: true,
        component: lazy(() =>
          import('src/views/Captaciones/AsignarPruebaTransmision')
        )
      },
      {
        path: '/captaciones/contactos',
        exact: true,
        component: lazy(() => import('src/views/Captaciones/Contactos'))
      },
      {
        path: '/captaciones/lista-procesos',
        exact: true,
        component: lazy(() => import('src/views/Captaciones/ListaProcesos'))
      },
      {
        path: '/captaciones/asignar-capacitaciones',
        exact: true,
        component: lazy(() =>
          import('src/views/Captaciones/AsignarCapacitaciones')
        )
      },
      {
        path: '/captaciones/linea-tiempo',
        exact: true,
        component: lazy(() =>
          import('src/views/Captaciones/ListaProcesos/LineaTiempo')
        )
      },
      {
        path: '/changelog',
        exact: true,
        component: lazy(() => import('src/views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('src/views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('src/views/Chat'))
      },
      {
        path: '/components/buttons',
        exact: true,
        component: lazy(() => import('src/views/Buttons'))
      },
      {
        path: '/components/cards',
        exact: true,
        component: lazy(() => import('src/views/Cards'))
      },
      {
        path: '/components/chips',
        exact: true,
        component: lazy(() => import('src/views/Chips'))
      },
      {
        path: '/components/forms',
        exact: true,
        component: lazy(() => import('src/views/Forms'))
      },
      {
        path: '/components/lists',
        exact: true,
        component: lazy(() => import('src/views/Lists'))
      },
      {
        path: '/components/modals',
        exact: true,
        component: lazy(() => import('src/views/Modals'))
      },
      {
        path: '/components/typography',
        exact: true,
        component: lazy(() => import('src/views/Typography'))
      },
      {
        path: '/concursos/',
        exact: true,
        component: lazy(() => import('src/views/Concursos'))
      },
      {
        path: '/concursos/:concurso_id',
        exact: true,
        component: lazy(() => import('src/views/Concursos/Competition'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/encuestas',
        exact: true,
        component: lazy(() => import('src/views/Encuestas'))
      },
      {
        path: '/encuestas/ingreso',
        exact: true,
        component: lazy(() => import('src/views/Encuestas/Ingreso'))
      },
      {
        path: '/encuestas/salida',
        exact: true,
        component: lazy(() => import('src/views/Encuestas/Salida'))
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('src/views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        component: lazy(() => import('src/views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('src/views/Mail'))
      },
      {
        path: '/management/customers',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('src/views/ProjectManagementList'))
      },
      {
        path: '/management/orders',
        exact: true,
        component: lazy(() => import('src/views/OrderManagementList'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        component: lazy(() => import('src/views/OrderManagementDetails'))
      },
      {
        path: '/mercadeo/calendario-sesion-fotos',
        exact: true,
        component: lazy(() =>
          import('src/views/Mercadeo/CalendarioSesionFotos')
        )
      },
      {
        path: '/mercadeo/disponibilidad-sesion-fotos',
        exact: true,
        component: lazy(() =>
          import('src/views/Mercadeo/DisponibilidadSesionFotos')
        )
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('src/views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        component: lazy(() => import('src/views/ProjectCreate'))
      },
      {
        path: '/projects/:id',
        exact: true,
        component: lazy(() => import('src/views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('src/views/ProjectList'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/social-feed',
        exact: true,
        component: lazy(() => import('src/views/SocialFeed'))
      },
      {
        path: '/talento-humano/disponibilidad-th',
        exact: true,
        component: lazy(() => import('src/views/TalentoHumano/Disponibilidad'))
      },
      {
        path: '/talento-humano/asignar-entrevistas',
        exact: true,
        component: lazy(() =>
          import('src/views/TalentoHumano/AsignarEntrevistas')
        )
      },
      {
        path: '/talento-humano/calendario-entrevistas',
        exact: true,
        component: lazy(() =>
          import('src/views/TalentoHumano/CalendarioEntrevistas')
        )
      },
      {
        path: '/transmision/calendario-pruebas-transmision',
        exact: true,
        component: lazy(() =>
          import('src/views/Captaciones/AsignarPruebaTransmision')
        )
      },
      {
        path: '/transmision/reporte-estados',
        exact: true,
        component: lazy(() => import('src/views/Transmision/ReporteEstados'))
      },
      {
        path: '/transmision/asistencias',
        exact: true,
        component: lazy(() => import('src/views/Transmision/Asistencias'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('src/views/GettingStarted'))
      },
      {
        path: '/soporte',
        exact: true,
        component: lazy(() => import('src/views/Soporte'))
      },
      {
        path: '/sedes',
        exact: true,
        component: lazy(() => import('src/views/ModuloSedes')),
      },
      {
        path: '/configuracion',
        exact: true,
        component: lazy(() => import('src/views/Configuracion'))
      },
      {
        path: '/configuracion/general',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigNombreLogo')
        )
      },
      {
        path: '/configuracion/turnos',
        exact: true,
        component: lazy(() =>
          import('src/views/Rooms/CrudTurn')
        )
      },
      {
        path: '/configuracion/metas',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfiguracionMetas')
        )
      },
      {
        path: '/configuracion/plataformas',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas')
        )
      },
      {
        path: '/configuracion/plataformas/livejasmin',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarJasmin')
        )
      },
      {
        path: '/configuracion/plataformas/streamate',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarStreamate')
        )
      },
      {
        path: '/configuracion/plataformas/cherrytv',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarCherryTv')
        )
      },
      {
        path: '/configuracion/plataformas/cams',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarCams')
        )
      },
      {
        path: '/configuracion/plataformas/skyprivate',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarSkyPrivate')
        )
      },
      {
        path: '/configuracion/plataformas/adultwork',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarAdultWork')
        )
      },
      {
        path: '/configuracion/master-adicional/adultwork/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarAdultWork')
        )
      },
      {
        path: '/configuracion/plataformas/cam4',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarCam4')
        )
      },
      {
        path: '/configuracion/plataformas/imlive',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarImLive')
        )
      },
      {
        path: '/configuracion/plataformas/bongacams',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarBongacams')
        )
      },
      {
        path: '/configuracion/master-adicional/bongacams/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarBongacams')
        )
      },
      {
        path: '/configuracion/plataformas/chaturbate',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarChaturbate')
        )
      },
      {
        path: '/configuracion/master-adicional/chaturbate/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarChaturbate')
        )
      },
      {
        path: '/configuracion/plataformas/flirt4free',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarFlirtFourFree')
        )
      },
      {
        path: '/configuracion/plataformas/stripchat',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarStripChat')
        )
      },
      {
        path: '/configuracion/plataformas/camsoda',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarCamSoda')
        )
      },
      {
        path: '/configuracion/plataformas/myfreecams',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarMyFreeCams')
        )
      },
      {
        path: '/configuracion/plataformas/xlove',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarXLove')
        )
      },
      {
        path: '/configuracion/plataformas/manyvids',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarManyvids')
        )
      },
      {
        path: '/configuracion/plataformas/xmodels',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/ConfigurarXModels')
        )
      },
      {
        path: '/configuracion/master-adicional/xmodels/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarXmodels')
        )
      },
      {
        path: '/configuracion/master-adicional/jasmin/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarJasmin')
        )
      },
      {
        path: '/configuracion/master-adicional/streamate/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarStreamate')
        )
      },
      {
        path: '/configuracion/master-adicional/stripchat/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarStripChat')
        )
      },
      {
        path: '/configuracion/master-adicional/flirt4free/:masterId',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfigurarPlataformas/CuentasMasterAdicionales/ConfigurarFlirtFourFree')
        )
      },
      {
        path: '/configuracion/categoria-descuentos',
        exact: true,
        component: lazy(() => import('src/views/Configuracion/CategoriaDescuentos'))
      },
      {
        path: '/configuracion/porcentajes-faltas',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/PorcentajesFaltas')
        )
      },
      {
        path: '/configuracion/pagos',
        exact: true,
        component: lazy(() =>
          import('src/views/Configuracion/ConfiguracionSeguridadSocial')
        )
      },
      {
        path: '/configuracion/tipo-de-caja-contable',
        exact: true,
        component: lazy(() => import('src/views/Configuracion/CategoriaDescuentos/TiposDeCuentas'))
      },
      {
        path: '/configuracion/periodos',
        exact: true,
        component: lazy(() => import('src/views/Configuracion/ConfiguracionPeriodos'))
      },
      {
        path: '/configuracion/notificar-roles',
        exact: true,
        component: lazy(() => import('src/views/Configuracion/ConfiguracionRolesNotificar'))
      },
      {
        path: '/mi-cuenta/mis-constancias',
        exact: true,
        component: lazy(() => import('src/views/PerfilModelos/MisConstancias'))
      },
      {
        path: '/mi-cuenta/mis-pagos',
        exact: true,
        component: lazy(() => import('src/views/PagosDescuentos/PagosIndividuales'))
      },
      {
        path: '/mi-perfil',
        exact: true,
        component: lazy(() => import('src/views/PerfilModelos'))
      },
      {
        //ruta/:user_id/ruta
        path: '/usuarios/:usuario_id/descuentos',
        exact: true,
        component: lazy(() => import('src/views/Usuarios2/Descuentos/DescuentosUsuarios'))
      },
      {
        path: '/mi-cuenta/mis-descuentos',
        exact: true,
        component: lazy(() => import('src/views/Usuarios2/Descuentos/DescuentosIndividuales'))
      },
      {
        path: '/listado-descuentos',
        exact: true,
        component: lazy(() => import('src/views/ListaDescuentos'))
      },
      {
        path: '/notifications',
        exact: true,
        component: lazy(() => import('src/views/Notifications'))
      },
      {
        path: '/escuela-modelos/escuela',
        exact: true,
        component: lazy(() => import('src/views/Escuela'))
      },
      {
        path: '/escuela-modelos/cursos',
        exact: true,
        component: lazy(() => import('src/views/Escuela/Cursos'))
      },
      {
        path: '/escuela-modelos/formularios',
        exact: true,
        component: lazy(() => import('src/views/Escuela/Formularios'))
      },
      {
        path: '/escuela-modelos/programacion',
        exact: true,
        component: lazy(() => import('src/views/Escuela/Programacion'))
      },
      {
        path: '/escuela-modelos/programacion/jornada/:jornadaId',
        exact: true,
        component: lazy(() => import('src/views/Escuela/Programacion/Modulos'))
      },
      {
        path:
          '/escuela-modelos/programacion/jornada/:jornadaId/modulo/:moduloId/',
        exact: true,
        component: lazy(() => import('src/views/Escuela/Programacion/Gestion'))
      },
      {
        path: '/escuela-modelos/programacion/estudiantes/:jornadaId',
        exact: true,
        component: lazy(() =>
          import('src/views/Escuela/Programacion/Estudiantes')
        )
      },
      {
        path: '/liquidacion-pagos',
        exact: true,
        component: lazy(() => import('src/views/Nomina/LiquidacionPagos'))
      },
      {
        path: '/liquidacion-pagos/:nominaId',
        exact: true,
        component: lazy(() => import('src/views/Nomina/LiquidacionPagos/InformacionPeriodo'))
      },
      {
        path: '/informacion-fiscal',
        exact: true,
        component: lazy(() => import('src/views/Nomina/InformacionFinanciera'))
      },
      {
        path: '/informacion-fiscal/:nominaId',
        exact: true,
        component: lazy(() => import('src/views/Nomina/InformacionFinanciera/InformacionPeriodo'))
      },
      {
        path: '/guias-xaratech',
        exact: true,
        component: lazy(() => import('src/views/GuideModule'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
