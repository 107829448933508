let message = '';
let title = '';
let isOpen = false;
let type = 'info';
let accepted = () => {};
let cancelled = () => {};
const subscribers = new Set();

const subscribe = subscriber => {
  subscribers.add(subscriber);
  return () => {
    unsubscribe(subscriber);
  };
};

const unsubscribe = subscriber => {
  subscribers.delete(subscriber);
};

const notify = () => {
  subscribers.forEach(subscriber => {
    subscriber();
  });
};

const open = (msg, titleprop = '', acceptedprop = () => {}, cancelledprop = () => {}, typeprop = 'info') => {
  message = msg;
  title = titleprop;
  isOpen = true;
  type = typeprop;
  accepted = acceptedprop;
  cancelled = cancelledprop;
  notify();
};

const close = (userAccepted) => {
  message = '';
  title = '';
  isOpen = false;
  if(userAccepted){
    accepted();
  } else {
    cancelled();
  }
  notify();
};

const getData = () => ({
  message,
  title,
  isOpen,
  type
});

const ConfirmService = {
  open,
  close,
  getData,
  subscribe,
};

export default ConfirmService;
