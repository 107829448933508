import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    // Por necima de los modales y debajo de los snackbar.
    // mobile stepper: 1000
    // speed dial: 1050
    // app bar: 1100
    // drawer: 1200
    // modal: 1300
    // snackbar: 1400
    // tooltip: 1500
    zIndex: theme.zIndex.snackbar - 1,
    color: '#fff'
  }
}));

const Loader = ({onClick, open}) => {

  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      onClick={onClick}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

Loader.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

Loader.propsDefault = {
  onClick: () => {},
  open: false,
};

export default Loader;