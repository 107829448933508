import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { Container, Grid, Button, Paper, Typography } from '@material-ui/core';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Header from './Header';
import TransferList from './TransferList';
import GetAppIcon from '@material-ui/icons/GetApp';
import url_api from './../../../constants/apiJasmine';
import Box from '@material-ui/core/Box';
import AuthService from './../../../services/AuthService';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  calendar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarButton: {
    backgroundColor: theme.palette.common.white
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const permissions = AuthService.permissions();

const can = (permission) => {
    return permissions.indexOf(permission)!==-1;
};

function AsignacionDiariaDetalle() {
  const classes = useStyles();
  //const [cargando, setCargando] = useState(false);
  const [listaNicknamesModelos, setListaNicknamesModelos] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
    checkedD: true,
    checkedE: true,
    checkedF: true,
    checkedG: true,
  });

  const nombresReportes = {
    checkedA: 'asistenciaGanancia',
    checkedB: 'asistenciaTiempo',
    checkedC: 'asistenciaGananciaTiempo',
    checkedD: 'categoriaGanancia',
    checkedE: 'categoriaTiempo',
    checkedF: 'categoriaGananciaCombinado',
    checkedG: 'categoriaTiempoCombinado',
  };

  const handleChangeCheck = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  //Este efecto carga los datos de las habitaciones
  useEffect(() => {
    moment.lang("es");
    return () => {};
  }, []);

  const onChangeTransferList = (listNicknamesSelecteds) => {
    setListaNicknamesModelos(listNicknamesSelecteds);
  }

  const onChangeDates = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const handleGenerateReportPagos = () => {
    let params = {
      fromDate: startDate.format('Y-MM-DD'),
      toDate: endDate.format('Y-MM-DD')
    };
    for(let i=0; i<listaNicknamesModelos.length; i++){
      params['modelos['+i+']'] = listaNicknamesModelos[i];
    }

    params['reportes[0]'] = 'asistenciaGanancia';
    params['reportes[1]'] = 'asistenciaTiempo';
    params['reportes[2]'] = 'asistenciaGananciaTiempo';
    
    openWindowWithPostRequest(params, 'pagos');
  }

  const handleGenerateReportAnalisis = () => {
    let params = {
      fromDate: startDate.format('Y-MM-DD'),
      toDate: endDate.format('Y-MM-DD')
    };
    for(let i=0; i<listaNicknamesModelos.length; i++){
      params['modelos['+i+']'] = listaNicknamesModelos[i];
    }

    let i=0;
    for (var key in state) {
      if(state[key]){
        params['reportes['+i+']'] = nombresReportes[key];
        i++;
      }
    }
    openWindowWithPostRequest(params, 'analisis');
  }

  const handleGenerateReportHistorico = () => {
    openWindowWithPostRequest({
      fecha_inicial: startDate.format('Y-MM-DD'),
      fecha_final: endDate.format('Y-MM-DD')
    }, 'asignacion');
  }

  const openWindowWithPostRequest = (params, url) => {
    var winName='Reportes';
    var winURL=url_api + 'jasmine-reporte/'+url;
    var windowoption='resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", winURL);
    form.setAttribute("target",winName);  
    for (var i in params) {
      if (params.hasOwnProperty(i)) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = params[i];
        form.appendChild(input);
      }
    }              
    document.body.appendChild(form);                       
    window.open('', winName,windowoption);
    form.target = winName;
    form.submit();                 
    document.body.removeChild(form);
    setTimeout(function() {
      window.close();
    }, 3000);
  }

  return (
      <Container
        maxWidth={false}
        className={classes.container}
      >
        <Box display={can('ver_modulo_reportes')?'block':'none'}>
          <Header subtitle="" title="REPORTE GENERAL" onChangeDates={onChangeDates}/>
          <Paper variant="outlined">
              <TransferList onChange={onChangeTransferList}></TransferList>
                  <FormGroup row style={{ padding: 25 }}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        xs={12} 
                        sm={12}
                    >
                        <Typography variant="h4" gutterBottom>
                            Tipos de reporte
                        </Typography>
                    </Grid>
                        <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        xs={12} 
                        sm={6}
                        >
                            <FormControlLabel
                            control={
                                <Checkbox checked={state.checkedA} 
                                    onChange={handleChangeCheck('checkedA')} 
                                    value="checkedA" 
                                />
                            }
                            label="Asistencia en ganancias($U)"
                            />
                            <FormControlLabel
                            control={
                                <Checkbox checked={state.checkedB} 
                                    onChange={handleChangeCheck('checkedB')} 
                                    value="checkedB" 
                                />
                            }
                            label="Aistencia en tiempos(Horas, minutos, segundos)"
                            />
                            <FormControlLabel
                            control={
                                <Checkbox checked={state.checkedC} 
                                    onChange={handleChangeCheck('checkedC')} 
                                    value="checkedC" 
                                />
                            }
                            label="Asistencia en ganancias($U) y tiempos(Horas, minutos y segundos)"
                            />
                        </Grid>
                        <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        xs={12} 
                        sm={6}
                        >
                            <FormControlLabel
                            control={
                                <Checkbox checked={state.checkedD} 
                                    onChange={handleChangeCheck('checkedD')} 
                                    value="checkedD" 
                                />
                            }
                            label="Categorias de ganancias($U)"
                            />
                            <FormControlLabel
                            control={
                                <Checkbox checked={state.checkedF} 
                                    onChange={handleChangeCheck('checkedF')} 
                                    value="checkedF" 
                                />
                            }
                            label="Categorias de ganancias($U) y sus porcentajes"
                            />
                            <FormControlLabel
                            control={
                                <Checkbox checked={state.checkedE} 
                                    onChange={handleChangeCheck('checkedE')} 
                                    value="checkedE" 
                                />
                            }
                            label="Categorias de tiempos(Horas, minutos y segundos)"
                            />
                            <FormControlLabel
                            control={
                                <Checkbox checked={state.checkedG} 
                                    onChange={handleChangeCheck('checkedG')} 
                                    value="checkedG" 
                                />
                            }
                            label="Categorias de tiempos(Horas, minutos y segundos) y sus porcentajes"
                            />
                        </Grid>
                  </FormGroup>
              <Grid
                  container spacing={2} justify="center" alignItems="center"
              >
                  <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleGenerateReportPagos}
                        disabled={listaNicknamesModelos.length===0}
                    >
                        <GetAppIcon />Reporte Pagos
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleGenerateReportAnalisis}
                        disabled={listaNicknamesModelos.length===0}
                    >
                        <GetAppIcon />Reporte Analisis
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleGenerateReportHistorico}
                    >
                        <GetAppIcon />Reporte Turnos
                    </Button>
                  </Grid>
              </Grid>
          </Paper>
        </Box>

      </Container>
      
  );
}

export default AsignacionDiariaDetalle;
