import axios from 'axios';

/**
 * Indica si un mimetype conincide con alguno de los de la lista de mimetypes aceptados.
 * @param {Array<string>} acceptedMimeTypes Contiene el listado de mimetypes que so válidos. Ej: ['text/html', 'image/gif', 'image/*']
 * @param {string} currentMimeType Es el mimetype que debe ser validado. Usado con ev.target.files[0].type Ej: 'image/gif'
 * @returns True en caso de que currentMimeType sea uno aceptado. False en caso contrario.
 */
export const isAcceptedMimeTypeFile = (acceptedMimeTypes, currentMimeType) => {
  const [currentType, currentExtension] = currentMimeType.split('/');
  for (let index = 0; index < acceptedMimeTypes.length; index++) {
    const [type, extension] = acceptedMimeTypes[index].split('/');
    if (type === currentType || type === '*') {
      if (extension === currentExtension || extension === '*') {
        return true;
      }
    }
  }
  return false;
};

/**
 * Carga una URL, la cual debe ser un recurso a una imagen y devulve
 * su valor como un string en base64
 * @param {Promise<string>} absoluteURL 
 */
 export const loadImageFromURL = async (absoluteURL) => {
  // Lanzamos la petición
  const response = await axios.get(`${absoluteURL}`, {responseType: 'blob'});
  var reader = new FileReader();
  reader.readAsDataURL(response.data);
  const promise = new Promise((resolve, reject) => {
    try {
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    } catch(error) {
      reject(error);
    }
  });
  return promise;
};

/**
 * Convertir File a base64
 * @param {File} file Archivo que se desea convertir en un string en B64
 * @returns Promise<string>
 */
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve('');
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
