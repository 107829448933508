import moment from "moment";

const subscribers = new Set();
let periodDays = {
  first_date: window.localStorage.periodFirstDate ? window.localStorage.periodFirstDate : undefined,
  period_days: window.localStorage.periodDays ? window.localStorage.periodDays : undefined,
}

const subscribe = subscriber => {
  subscribers.add(subscriber);

  return () => {
    unsubscribe(subscriber);
  };
};

const unsubscribe = subscriber => {
  subscribers.delete(subscriber);
};

const notify = () => {
  subscribers.forEach(subscriber => {
    subscriber();
  });
};

const getPeriodDays = () => {
  return periodDays.period_days;
};

const getPeriodFirstDate = () => {
  return moment(periodDays.first_date).valueOf();
};

const setPeriodDays = (countDays) => {
  periodDays.period_days = countDays;
  notify();
};

const setPeriodFirstDate = (firstDate) => {
  periodDays.first_date = moment(firstDate);
  notify();
};

const PeriodObservable = {
  subscribe,
  unsubscribe,
  /**
   * Devuelve el valor de la cantidad de días del período almacenada. Puede ser un número o una letra
   * referente a la temporalidad (q para quincenas, m para meses)
   */
  getPeriodDays,
  getPeriodFirstDate,
  setPeriodDays,
  setPeriodFirstDate,
};

export default PeriodObservable;
