import palette from "../palette";

export default {
  root: {
    padding: '16px 24px',
    backgroundColor: palette.background.default,
  },
  action: {
    color: palette.text.primary,
  }
};
