import axios from 'axios';
import url_server from './../constants/server';
import url_api from './../constants/apiJasmine';

// Indica si es la primera vez que se chequea
let firstCheck = true;

// Id del proceso que se ejecuta periódicamente
let pid = 0;

// Tiempo necesario para darse cuenta que hay alguna actualización
const intervalCheckMilisecond = 60000;

// Promesa que espera que hay una actualización
let promise;

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

const checkAvailableUpdate = (callbackIsAvailable) => {
    axios.get('/', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      })
    .then( data => {
        if(firstCheck){
            localStorage.setItem( 'version', data.data );
            firstCheck = false;
        }
        const version = localStorage.getItem('version');
        if( version !== data.data ){
            callbackIsAvailable();
            stop();
        }
    }, error => {
    });
}

const saveFirst = () => {
    axios.get('/', {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
    })
    .then( data => {
        localStorage.setItem( 'version', data.data );
        firstCheck = false;
    }, error => {
    });
}
/**
 * Inicia el proceso de búsqueda de actualizaciones.
 * Devuelve una promesa con la cual termina cuando detecta que existe una actualización.
 */
const start = () => {
    promise = new Promise((resolve, reject) => {

        saveFirst();

        pid = setInterval( () => {
            if(!isLocalhost){
                checkAvailableUpdate( () => {
                    resolve({ message: 'Actualización disponible.'});
                });
            }
        }, intervalCheckMilisecond);
    });

    return promise;
}

/**
 * Para la búsqueda de actualizaciones.
 */
const stop = () => {
    clearInterval( pid );
}

export default {
    start,
    stop
};