export const getMessageFromErrorResponse = (error) => {
  const data = error.response.data;
  let message = '';
  if (data.errors) {
    message = Object.keys(data.errors)
      .map(key => data.errors[key])
      .reduce((accum, current) => `${accum} ${current}`, '');
  } else {
    message = data.message;
  }
  return message;
}

export const getMessageFromErrorBlobResponse = (error) => {
  const promise = new Promise((resolve, reject) => {
    try{
      const fr = new FileReader();
      fr.onload = (e) => {
        const error = JSON.parse(e.target.result);
        resolve(error.message);
      };
      fr.readAsText(error.response.data);
    } catch(jserror) {
      reject('Ocurrió un error a intentar convertir la respuesta.');
    }
  });

  return promise;
}
