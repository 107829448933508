import React from 'react';
import { useHistory } from 'react-router';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Tooltip } from '@material-ui/core';

function GuideButton() {
  const history = useHistory();

  const handleClick = () => {
    history.push('/guias-xaratech');
  };

  return (
    <Tooltip title="Ayuda">
      <IconButton onClick={handleClick}>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  )
}

export default GuideButton