import React, { Suspense, useState } from 'react';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Backdrop, CircularProgress } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import AuthService from './../../services/AuthService';
import AlertService from 'src/services/AlertService';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff'
  },
}));

function Dashboard({ route }) {
  const classes = useStyles();
  const history = useHistory();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [cargando, setCargando] = useState(false);

  const handleLogout = () => {
    setCargando(true);
    AuthService.logout().then(
      () => {
        setCargando(false);
        history.push('/');
      },
      error => {
        setCargando(false);
        AlertService.error(error.response.data.message, 'Error');
      }
    );
  };

  return (
    <>
      <TopBar
        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
        onLogout={handleLogout}
      />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
            <Backdrop
              className={classes.backdrop}
              open={cargando}
              onClick={() => {}}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Suspense>
        </div>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
