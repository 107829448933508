import palette from "../palette";

export default {
  root: {
    color: palette.text.primary,
  },
  gutterBottom: {
    marginBottom: 10,
  },
  colorTextSecondary: {
    color: palette.text.primary,
  },
  colorTextPrimary: {
    color: '#212121',
  },
  h1: {
    color: palette.text.secondary,
    fontWeight: 500,
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    color: palette.text.secondary,
  },
  h3: {
    color: palette.text.secondary,
  },
  h4: {
    color: palette.text.secondary,
  },
  h5: {
    color: palette.text.primary,
  },
  h6: {
    color: palette.text.secondary,
  },
  body1: {
    color: palette.text.secondary,
  },
  body2: {
    color: palette.text.secondary,
  },
  subtitle1: {
    color: palette.text.secondary,
  },
};
