import React, {useState} from 'react';
import Page from 'src/components/Page';
import Notices from './Notices';
import Podium from './Podium';
import AuthService from 'src/services/AuthService';

function Presentation() {

  const [podium, setPodium] = useState(false);

  
  const handleExistPodium = () => {
    setPodium(true);
  }

  handleNotExistPodium
  const handleNotExistPodium = () => {
    setPodium(false);
  }

  return (
    <Page title="XaraTech" >
      {
        AuthService.can('hall_of_fame') ? 
        <Podium 
          handleExistPodium={handleExistPodium}
          handleNotExistPodium={handleNotExistPodium}
          statePodium={podium}
        />
        :
        <></>
      }
      <Notices podium={podium} />
    </Page>
  );
}

export default Presentation;
