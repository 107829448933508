import palette from '../palette';

export default {
  root: {
    color: palette.primary.dark,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      //secondary: palette.primary.light,
    },
  },
  colorInherit: {
    color: palette.primary.dark,
  },
};
