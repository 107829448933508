import React, { useState, useEffect } from 'react';
import UniversalLoading from 'src/observers/UniversalLoading';
import Loader from './Loader';

/**
 * Este componente muestra un Loader sobre toda la pantalla, incluyendo modales.
 * Hace uso del <Loader />.
 * Se encuentra directamente en <App />
 */
export default function UniversalLoadingComponent() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(UniversalLoading.getLoading());
    const unsubscribe = UniversalLoading.subscribe(() => {
      setLoading(UniversalLoading.getLoading());
    });
    return unsubscribe;
  }, []);

  return (
    <Loader open={loading} />
  )
}
