import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';
import mock from 'src/utils/mock';

mock.onGet('/api/calendar').reply(200, {
  draft: [],
  events: [
    {
      id: uuid(),
      color: colors.green['700'],
      start: moment('2020-11-25 17:00:00'),
      end: moment('2020-11-25 18:00:00')
    },
    {
      id: uuid(),
      title: 'Call Samantha',
      desc: 'Inform about new contract',
      color: colors.green['700'],
      start: moment('2020-11-25 16:00:00'),
      end: moment('2020-11-25 17:00:00')
    },
    {
      id: uuid(),
      title: 'Call Samantha',
      desc: 'Inform about new contract',
      color: colors.green['700'],
      start: moment('2020-11-25 15:00:00'),
      end: moment('2020-11-25 16:00:00')
    },
    {
      id: uuid(),
      title: 'Call Samantha',
      desc: 'Inform about new contract',
      color: colors.green['700'],
      start: moment('2020-11-26 17:00:00'),
      end: moment('2020-11-26 18:00:00')
    },
    {
      id: uuid(),
      title: 'Call Samantha',
      desc: 'Inform about new contract',
      color: colors.green['700'],
      start: moment('2020-11-27 17:00:00'),
      end: moment('2020-11-27 18:00:00')
    },
  ]
});
