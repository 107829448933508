import moment from "moment";

/**
 * 
 * @param {number, string} intervalos Hace referencia a la cantidad de días que tiene el período
 * @param {date} fechaActual fecha actual para conocer el límite máximo de los rangos por períodos
 * @returns cantidad en enteros dependiendo de la configuración especificada.
 */
const getPeriodDays = (intervalos, fechaActual) => {
  if (intervalos === 'm') {
    return parseInt(moment(fechaActual).daysInMonth());
  }
  if (intervalos === 'q' && moment(fechaActual).format('DD') === '01') {
    return 15;
  }
  if (intervalos === 'q' && moment(fechaActual).format('DD') !== '01') {
    return parseInt(moment(fechaActual).daysInMonth()) - 15;
  } else {
    return parseInt(intervalos);
  }
};

export default getPeriodDays;