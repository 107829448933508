import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as LiveJasmin } from 'src/assets/svg/LiveJasmin.svg';
import 'src/assets/fonts/Asap.ttf';;
import 'src/assets/fonts/Dosis.ttf';

const LiveJasminIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <LiveJasmin />
    </SvgIcon>
  )
}

export default LiveJasminIcon;