import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  List,
  ListSubheader,
  Typography,
  Hidden,
  Box,
  colors,
  Grid,
  Divider
} from '@material-ui/core';
import NavItem from 'src/components/NavItem';
import useNavConfig from './navConfig';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import palette from 'src/theme/palette';
import AuthService from 'src/services/AuthService';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#11192A',
  },
  logo: {
    marginBottom: 4,
    textAlign: 'center',
  },
  barLogo: {
    maxWidth: 200,
    height: 'auto',
    margin: 16,
  },
  divider: {
    margin: '0px 23px',
    backgroundColor: 'rgb(255 255 255 / 21%)',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    height: '100%'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: '#6A67FF',
  },
  footer: {
    color: palette.text.primary,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
  },
  nameNav: {
    color: palette.text.primary,
    fontSize: 13,
  },
}));

function renderNavItems({
  items, subheader, key, ...rest
}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader style={{ color: '#fff', fontSize: 20, fontFamily: 'Raleway' }} disableSticky>{subheader}</ListSubheader>}
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0
}) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
      />
    );
  }

  return acc;
}



function NavBar({
  openMobile,
  onMobileClose,
  className,
  ...rest
}) {
  const classes = useStyles();
  const location = useLocation();
  const navConfig = useNavConfig();

  const [user, setUser] = useState({});

  useEffect(() => {
    let dataUser = AuthService.user();
    if (dataUser) {
      setUser(dataUser);
    };
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PerfectScrollbar>
        <Grid className={classes.logo}>
          <RouterLink to="/" >
            <img
              alt="Logo"
              src={'/images/login/logo.png'}
              className={classes.barLogo}
            />
          </RouterLink>
          <Divider className={classes.divider} />
          <Typography className={classes.nameNav} variant='h5'>{user.fullname}</Typography>
          <Typography className={classes.nameNav} variant='h5'>{user.role?.toUpperCase()}</Typography>
        </Grid>
        <Divider className={classes.divider} />
        <nav className={classes.navigation}>
          {navConfig.map((list) => renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader
          }))}
        </nav>
        <Grid className={classes.profile}>

        </Grid>
      </PerfectScrollbar>
      <Box m="auto">
        <Typography variant="caption" className={classes.footer}>POWERED BY XARATECH</Typography>
      </Box>
    </Grid>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
