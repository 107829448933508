import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import palette from 'src/theme/palette';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F1F4F8',
    position: 'absolute',
    marginTop: 65,
    right: '0%',
    outline: 'none',
    boxShadow: theme.shadows[20],
    height: 'auto',
    width: 300,
    maxHeight: '94%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end',
    backgroundColor: '#F1F4F8'
  },
  avatar: {
    width: 230,
    height: 230
  },
  gridAvatar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  image: {
    width: '100%'
  },
  iconButton: {
    backgroundColor: 'black'
  },
  editIcon: {
    color: 'white'
  },
  overEditIcon: {
    color: 'black'
  },
  header: {
    backgroundColor: palette.background.default,
  },
  '.Mui-disabled': {
    backgroundColor: '#000'
  },
}));

/**
 * Despliega un Modal
 * @param {any} param0
 * @returns
 */
const ModalNotifications = ({ open, onClose, content, actions }) => {
  const classes = useStyles();

  const alertRef = useRef(null);

  const executeScroll = () => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView();
    }
  };

  useEffect(executeScroll, []);

  return (
    <Modal open={open} onClose={onClose} >
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          subheader={
            <Link to="/notifications">
              <Button
                variant="contained"

                onClick={onClose}
                style={{ width: '100%' }}
              >
                Ver Todas
              </Button>
            </Link>
          }
        />
        <CardContent>{content}</CardContent>
        <CardActions className={classes.actions}>{actions}</CardActions>
      </Card>
    </Modal>
  );
};

ModalNotifications.propTypes = {
  /**
   * Indica si abre o cierra
   */
  open: PropTypes.bool,
  /**
   * Se lanza cuando se cierra el formulario
   */
  onClose: PropTypes.func,
  /**
   * Componente para mostrar. Puede ser un formulario o un texto
   */
  content: PropTypes.element,
  /**
   * Aquí iría el footer del modal. Deberían ser botones.
   */
  actions: PropTypes.element,
  /**
   * Un string con el título de la ventana.
   */
  title: PropTypes.string
};

ModalNotifications.propsDefault = {
  open: false,
  content: <div>...</div>,
  actions: <div>...</div>,
  title: ''
};

export default ModalNotifications;
