import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
import PrivateRoute from 'src/views/PrivateRoute'
import AuthService from 'src/services/AuthService';

function Dashboard({ route }) {
  useEffect(() => {
    if (AuthService.user()) {
      AuthService.refreshUser();
    }
  }, [])

  return (
    <>
      <PrivateRoute
        component={(
          <Body route={route} />
        )} />
    </>
  )
}
Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
