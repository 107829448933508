let message = '';
let title = '';
let isOpen = false;
let type = 'info';
const subscribers = new Set();

const subscribe = subscriber => {
  subscribers.add(subscriber);
  return () => {
    unsubscribe(subscriber);
  };
};

const unsubscribe = subscriber => {
  subscribers.delete(subscriber);
};

const notify = () => {
  subscribers.forEach(subscriber => {
    subscriber();
  });
};

const open = (msg, titleprop = '', typeprop = 'info') => {
  message = msg;
  title = titleprop;
  isOpen = true;
  type = typeprop;
  notify();
};

const close = () => {
  message = '';
  title = '';
  isOpen = false;
  notify();
};

const info = (msg, titleprop = 'Información') => {
  open(msg, titleprop, 'info');
};

const error = (msg, titleprop = 'Error') => {
  open(msg, titleprop, 'error');
};
const success = (msg, titleprop = 'Éxito') => {
  open(msg, titleprop, 'success');
};
const warning = (msg, titleprop = 'Cuidado') => {
  open(msg, titleprop, 'warning');
};

const getData = () => ({
  message,
  title,
  isOpen,
  type
});

const AlertService = {
  close,
  getData,
  subscribe,
  info,
  error,
  success,
  warning
};

export default AlertService;
