/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Hidden,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import AuthService from './../../services/AuthService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Bell from 'src/views/Notifications/Bell';
import ShowNewNotifications from 'src/components/ShowNewNotifications';
import { url_logo } from 'src/constants/server';
import GuideButton from 'src/views/GuideModule/GuideButton';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.26)',
  },
  flexGrow: {
    flexGrow: 1
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 10,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    width: 25
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  logoutButton: {
    padding: 0,
    margin: 10,
  },
  role: {
    paddingRight: 20
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff'
  },
  topLogo: {
    height: 35,
    width: 'auto',
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  titulo: {
    color: '#6A67FF',
    fontWeight: 'bold',
  },
  tipografia: {
    fontFamily: 'Raleway',
  },
}));

function TopBar({
  onOpenNavBarMobile,
  className,
  onLogout,
  ...rest
}) {
  const classes = useStyles();
  const [user, setUser] = useState({});

  useEffect(() => {
    let mounted = true;

    let dataUser = AuthService.user();
    if (dataUser) {
      setUser(dataUser);
    };

    return () => {
      mounted = false;
    };
  }, []);

  const matches = useMediaQuery('(min-width:375px)');

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color='inherit'
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="primary"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        {
          matches ?
            <Typography variant='h1' className={classes.tipografia}>
              We <span className={classes.titulo}>cam</span> do it
            </Typography>
            :
            ''
        }
        <div className={classes.flexGrow} />
        <ShowNewNotifications />
        <img
          alt="Logo"
          src={url_logo}
          className={classes.topLogo}
        />
        <Bell />
        <GuideButton />
        <Tooltip title="Salir">
          <IconButton
            className={classes.logoutButton}
            color="primary"
            onClick={onLogout}
          >
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  onLogout: PropTypes.func
};

TopBar.defaultProps = {
  onLogout: () => { }
};

export default TopBar;
