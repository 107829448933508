/**
 * Suscriptores
 */
const subscribers = new Set();
let permissions = null;

/**
 * Inicializa los permisos en la primera carga
 */
const init = () => {
    try{
        permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    }catch(e){
        permissions = [];
        localStorage.setItem('permissions', JSON.stringify(permissions));
    }
};

/**
 * Almacena los permisos en caso de que hayan cambiado y notifica
 * a los observadores. En caso de que inicialmente no se tuviesen permisos
 * no se notificará.
 * @param {array} Arreglo con la nueva lista de permisos 
 * @returns True si los permisos son diferentes a los que ya tenía (han cambiado), false
 * en caso que no hayan cambiado.
 */
const setPermissions = newPermissions => {
    const difference1 = newPermissions.filter(x => !permissions.includes(x)).length;
    const difference2 = permissions.filter(x => !newPermissions.includes(x)).length;

    if(difference1 + difference2 !== 0){
        // Actualizamos los permisos en memoria y los del storage
        permissions = newPermissions;
        localStorage.setItem('permissions', JSON.stringify(newPermissions));
        // Se notifica
        notify([...permissions], [...newPermissions]);
        return true;
    }
    return false;
};

/**
 * 
 * @returns Array<string> Devuelve el listado de permisos actualizado
 */
const getPermissions = () => {
    return [...permissions];
};

/**
 * Elimina todos los permisos almacenados y notifica a los observadores
 */
const clearPermissions = () => {
    localStorage.removeItem('permissions');
    notify([...permissions], []);
};

/**
 * Notifica a los observadores
 * @param {Array<string>} oldPermissions Permisos antes de la actualización
 * @param {Array<string>} permissions Permisos actuales
 */
const notify = (oldPermissions, permissions) => {
    subscribers.forEach(subscriber => subscriber(oldPermissions, permissions));
}

/**
 * Se suscribe como observador. Debe aceptar como parámetros (Array<string>,Array<string>) donde
 * el primer argumento son los permisos antes de la actualización y el segundo parámetro
 * son los permisos actuales.
 * @param {Function} observer 
 * @returns 
 */
const subscribe = observer => {
    subscribers.add( observer );
    observer([...permissions], [...permissions]);

    return {
        dispose: () => {
            subscribers.delete(observer);
        }
    };
};

/**
 * Indica si el usuario tiene el permiso.
 * @param {string} permission 
 * @returns boolean
 */
const can = (permission) => {
    return getPermissions().indexOf(permission)!==-1;
};

const PermissionObservable = {
    setPermissions,
    clearPermissions,
    getPermissions,
    subscribe,
    can,
};

init();

export default PermissionObservable;
