import palette from '../palette';

export default {
  paper: {
    color: palette.text.secondary,
  },
  option: {
    '&:hover': {
      backgroundColor: '#F0F0FF',
    },
  },
};