import palette from "../palette";

export default {
  root: {
    color: palette.text.primary,
    '&:hover': {
      backgroundColor: '#6A67FF'
    },
  },
  colorInherit: {
    color: palette.icon,
  },
  contained: {
    color: '#ffffff',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
    backgroundColor: '#6A67FF',
    '&:hover': {
      backgroundColor: '#4a48b2'
    }
  },
  textPrimary: {
    color: 'white',
    backgroundColor: '#6A67FF',
    '&:hover': {
      color: 'white',
      backgroundColor: '#6A67FF',
    },
  },
};
