export default {
  //se calcula el ancho del topbar menos el ancho del navbar
  root: {
    width: 'calc(100% - 256px)',
  },
  //se calcula si es menor que max-width el topbar toma el ancho completo
  positionFixed: {
    '@media (max-width: 1280px)': {
      width: '100% ',
    },
  }
};