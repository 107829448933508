import React from 'react';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import ModalForm from 'src/components/ModalForm';
import { makeStyles } from '@material-ui/styles';
import url_server, { url_logo } from 'src/constants/server';
import Markdown from 'src/components/Markdown';

const useStyles = makeStyles((theme) => ({
  containerNoticeMain: {
    maxWidth: 1200,
    width: 1200,
    display: 'flex',
    flexDirection: 'column',
  },
  contentNoticeMain: {
    paddingLeft: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  titleNoticeMain: {
    textTransform: 'capitalize',
    marginBottom: 16,
  },
  subtitleNoticeMain: {
    textTransform: 'capitalize',
    marginBottom: 16,
  },
  bodyNoticeMain: {
    marginBottom: 16,
  },
  footerNoticeMain: {
    display: 'flex',
    justifyContent: 'end',
  },
  publishedNoticeMain: {
    paddingTop: 5,
    color: '#a7a7a7',
    flexGrow: 1,
  },
  contenido: {
    '& > *': {
      color: 'black',
    },
    '& > * > *': {
      color: 'black',
    },
  },
  '& > * > *': {
    color: 'black',
  },
}));

const ModalNotices = ({ notice, open, onClose }) => {

  const lg = useMediaQuery('(max-width: 1560px)');
  const md = useMediaQuery('(max-width: 958px)');
  const sm = useMediaQuery('(max-width: 760px)');

  const classes = useStyles();

  const body = (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
        {
          notice?.image_path
            ?
            (
              <img
                style={{ width: '100%' }}
                src={notice?.image_path ? `${url_server}/${notice?.image_path}` : url_logo}
              />
            )
            :
            (
              <></>
            )
        }
      </Grid>
      <Grid item xs={12} md={12} style={{ padding: " 0px ", marginLeft: 16 }}>
        <Typography variant='h3' component={'h3'}>{notice?.title}</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant='h4' component={'h4'}>{notice?.sub_title}</Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.contenido}>
        <Markdown
          className={classes.contenido}
          escapeHtml={false}
          source={notice?.content}
        />
      </Grid>
      {
        notice?.created_at &&
        (
          <Grid item xs={12} md={6}>
            <Typography variant='h6' component={'p'}>{notice?.created_at}</Typography>
          </Grid>
        )
      }
    </Grid >
  );

  return (
    <ModalForm
      actions={
        <div>
          <Button
            variant='contained'
            onClick={onClose}
          >
            Salir
          </Button>
        </div>
      }
      content={body}
      onClose={onClose}
      open={open}
      title='NOTICIA'
    />
  )
}

export default ModalNotices;
