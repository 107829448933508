import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import Loader from 'src/components/Loader';
import NotificationsService from 'src/services/NotificationsService';
import ModalForm from 'src/components/ModalForm';
import LeerTodas from '../LeerTodas';
import moment from 'moment';

const Notification = ({ open, onClose, notificacionId, notificaciones }) => {
  const [cargando, setCargando] = useState(false);
  const [notificacion, setNotificacion] = useState([]);

  //Api
  const consultarApi = useCallback(() => {
    setCargando(true);
    setNotificacion([]);
    if (Boolean(
      notificaciones.find(notificacion => notificacion.id === notificacionId)
    )) {
      if (Boolean(
        notificaciones.find(
          notificacion =>
            notificacion.id === notificacionId && !notificacion.leido
        )
      )) {
        NotificationsService.leerNotificacion(notificacionId);
      }
      setNotificacion(
        notificaciones.find(notificacion => notificacion.id === notificacionId)
      );
      setCargando(false);
    }
  }, [notificacionId]);

  useEffect(() => {
    setCargando(true);
    consultarApi();
  }, [notificacionId]);

  const body = (
    <Grid container>
      <Grid>
        <Grid key={notificacion.id} container redirection="column">
          
          <Grid container direction="column">
            <Grid container direction="row">
              <Grid container direction="row-reverse"
                style={{
                  fontSize: 13,
                  fontWeight: 'bold',
                }}
              >
                <Typography>
                  {moment(notificacion.created_at).format(
                    'dddd, DD MMMM YYYY - HH:mm'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <img src="/images/notification/Up.png" style={{ width: "100%" }} />
              <Typography
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  font: "condensed 120% message-box",
                }}
              >
                {notificacion.contenido}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Loader open={cargando} />
      <Grid container item xs={12} direction="column" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          style={{ marginTop: 30, marginBottom: -30 }}
        >
          Cerrar
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <ModalForm
      content={body}
      open={open}
      onClose={() => {
        onClose(true);
      }}
      title={notificacion.titulo}
    />
  );
};

export default Notification;
