import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme } from './theme';
import { Snackbar, Button } from '@material-ui/core';
import { configureStore } from './store';
import routes from './routes';
import CookiesNotification from './components/CookiesNotification';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import AuthService from './services/AuthService';
import UpdateManager from './services/UpdateManager';
import PermissionObservable from './observers/PermissionObservable';
import 'moment/locale/es';
import moment from 'moment';
import Success from './components/Success';
import AlertService from './services/AlertService';
import ConfirmService from './services/ConfirmService';
import PeriodObservable from './observers/PeriodObservable';
import UniversalLoadingComponent from './components/UniversalLoadingComponent';
import DialogWithComments from './components/DialogWithComments';

const history = createBrowserHistory();
const store = configureStore();
AuthService.configureAxios();
moment.locale('es');
window.addEventListener('storage', event => { 
  if(isNaN(event.newValue)){
    PeriodObservable.setPeriodDays(
      parseInt(event.storageArea.periodDays),
    );
    PeriodObservable.setPeriodFirstDate(
      event.storageArea.periodFirstDate,
    );
  }
  else{
    PeriodObservable.setPeriodDays(
      parseInt(event.newValue),
    );
  }
  if(moment(event.newValue, 'YYYY-MM-DD', true).isValid()){
    PeriodObservable.setPeriodFirstDate(
      parseInt(event.newValue),
    );
  }
})

function App() {
  const [open, setOpen] = useState(false);
  const [openPermissionMessage, setOpenPermissionMessage] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    UpdateManager.start().then(() => {
      setOpen(true);
    });
  }, []);

  useEffect(() => {
    // AuthService.refreshToken();
    const permisionObservable = PermissionObservable.subscribe(
      (oldPermissions, permissions) => {
        if (firstLoad) {
          setFirstLoad(false);
        } else {
          AlertService.warning('Sus permisos han cambiado', 'Información');
          setOpenPermissionMessage(true);
        }
      }
    );
    return () => {
      permisionObservable.dispose();
    };
  }, []);

  const handleCloseAlert = () => {
    AlertService.close();
  };

  useEffect(() => {
    AlertService.subscribe(() => {
      setOpenAlert(AlertService.getData().isOpen);
    });
  }, []);

  useEffect(() => {
    ConfirmService.subscribe(() => {
      setOpenConfirm(ConfirmService.getData().isOpen);
    });
  }, []);

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <StylesProvider direction={'ltr'}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <CookiesNotification />
              {renderRoutes(routes)}
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => { }}
                message={'Hay actualizaciones disponibles.'}
                action={
                  <Button
                    size="small"
                    variant='contained'
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Actualizar
                  </Button>
                }
              />
              <Snackbar
                open={openPermissionMessage}
                autoHideDuration={6000}
                onClose={() => setOpenPermissionMessage(false)}
                message={'Sus permisos han cambiado.'}
              />
              <Success
                open={openAlert}
                onClose={handleCloseAlert}
                title={AlertService.getData().title}
                message={AlertService.getData().message}
                type={AlertService.getData().type}
              />
              <DialogWithComments
                open={openConfirm}
                title={ConfirmService.getData().title}
                textContent={ConfirmService.getData().message}
                onClose={(accepted) => ConfirmService.close(accepted)}
              />
              <UniversalLoadingComponent />
            </Router>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
