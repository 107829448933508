export default {
  root: {
    backgroundColor: '#fff'
  },
  elevation1: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
  },
  elevation8: {
    boxShadow: '0px 5px 5px -3px rgb(0, 0, 0, 20%), 0px 8px 10px 1px rgb(0, 0, 0, 14%), 0px 3px 14px 2px rgb(0, 0, 0, 12%)',
  },
};
