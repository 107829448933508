import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import palette from 'src/theme/palette';
import Loader from 'src/components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 'auto',
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    borderRadius: '20px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  header: {
    backgroundColor: palette.background.default,
    textAlign: 'center'
  },
  btnColor: {
    backgroundColor: '#bbbbbb'
  },
  icon: {
    textAlign: 'center'
  },
  modal: {
    borderRadius: '20px'
  }
}));

function LeerTodas({
  open,
  onClose,
  onConfirm,
  className,
  cargando,
  ...rest
}) {
  const classes = useStyles();
  const [enviando, setEnviando] = useState(cargando)
  
  useEffect(() => {
    setEnviando(cargando);
  }, [cargando])

  if (!open) {
    return false;
  }

  return (
    <>
      <Loader open={enviando} />
      <Modal onClose={onClose} open={open}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            className={classes.header}
            title="MARCAR TODAS COMO LEÍDAS"
          />
            
          <CardContent style={{ textAlign: 'center' }}>
            <Warning style={{ fontSize: 100 }} className={classes.icon} />
            <Typography variant="body1">
              ¿Está seguro que desea marcar todas las notificaciones como Leídas?
            </Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              disabled={enviando}
              onClick={onConfirm}
              variant="contained"
              color="primary"
            >
              MARCAR TODAS COMO LEÍDAS
            </Button>
            <Button onClick={onClose} variant="contained" disabled={enviando}>
              SALIR
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
}

LeerTodas.propTypes = {
  className: PropTypes.string,
  usuario: PropTypes.any,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool
};

LeerTodas.defaultProps = {
  open: false,
  onClose: () => { },
  onConfirm: () => { }
};

export default LeerTodas;
