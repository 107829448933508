import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1black,
    padding: 5,
    color: palette.background.default,
  },
  head: {
    color: palette.background.default,
    fontWeight: 'bold',
  },
  body: {
    color: palette.text.secondary,
  },
  stickyHeader: {
    backgroundColor: palette.background.paper,
  },
};
