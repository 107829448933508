import React, { useEffect, useState } from 'react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import url_server, { url_logo } from 'src/constants/server';

const useStyles = makeStyles((theme) => {
  const widthCard = 1200 / 4 - 5;
  const widthContent = widthCard - theme.spacing(3) * 2;

  return {
    cardNotice: {
      maxWidth: widthCard,
      width: widthCard,
      marginTop: theme.spacing(2),
      height: 230,
      maxHeight: 230,
      cursor: 'pointer',
    },
    notice: {
      maxWidth: widthContent,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
    },
    noticeWithoutImg: {
      marginTop: (validImg) => validImg ? '15%' : 0,
    },
    imgNotice: {
      maxHeight: parseInt(widthContent / 2),
      maxWidth: widthContent,
      width: widthContent,
      cursor: 'pointer',
    },
    titleNotice: {
      textTransform: 'capitalize',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      'line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      '-moz-box-orient': 'vertical',
      'box-orient': 'vertical'
    },
    publishedNotice: {
      paddingTop: 5,
      color: '#a7a7a7',
    },
  }
});


const NoticeSmall = ({ notice, onOpenModal }) => {

  const classes = useStyles({ validImg });

  const [validImg, setValidImg] = useState(false);

  useEffect(() => {
    if (notice.image_path === null) {
      setValidImg(true);
    } else {
      setValidImg(false);
    }
  }, [notice.image_path]);

  return (
    <Card
      className={classes.cardNotice}
      onClick={(e) => onOpenModal(notice)}
    >
      <CardContent>
        <div className={String(validImg ? classes.noticeWithoutImg : classes.notice)}>
          {
            notice.image_path
              ?
              (
                <img
                  className={classes.imgNotice}
                  src={notice.image_path ? `${url_server}/${notice.image_path}` : url_logo}
                  style={{ objectFit: 'cover' }}
                />
              )
              :
              (
                <></>
              )
          }
          <Typography className={classes.titleNotice}>
            {notice.title}
          </Typography>
          {
            !notice.image_path &&
            (
              <Typography className={classes.titleNotice}>
                {notice.sub_title}
              </Typography>
            )
          }
          <Typography className={classes.publishedNotice}>
            {notice.created_at}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default NoticeSmall;