import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  textColor: {
    color: 'black'
  }
}));

const DialogWithComments = ({ open, title, textContent, withComments, cancelText, agreeText, labelComments, onClose }) => {

  const classes = useStyles();
  const [comments, setComments] = useState('');

  return (
    <div>
      <Dialog
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.textColor}>
            {textContent}
          </DialogContentText>
          {
            withComments
              ? <TextField
                autoFocus
                margin="dense"
                label={labelComments}
                type="text"
                value={comments}
                onChange={(ev) => setComments(ev.target.value)}
                fullWidth
              />
              : <></>
          }
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => { onClose(false, comments) }} >
            {cancelText}
          </Button>
          <Button onClick={() => { onClose(true, comments) }} variant="contained" color='primary'>
            {agreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DialogWithComments.propTypes = {
  cancelText: PropTypes.string,
  agreeText: PropTypes.string,
  title: PropTypes.string,
  textContent: PropTypes.string,
  labelComments: PropTypes.string,
  onClose: PropTypes.func,
};

DialogWithComments.defaultProps = {
  cancelText: 'Cancelar',
  agreeText: 'Aceptar',
  title: 'Confirmación',
  textContent: '¿Está usted de acuerdo?',
  labelComments: 'Observaciones',
  onClose: () => { },
};

export default DialogWithComments;