import palette from "../palette";

export default {
  root: {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.background.primary,
    },
    '&:hover .Mui-error': {
      borderColor: palette.error.light,
    },
  },
  notchedOutline: {
    borderColor: palette.background.primary,
  },
};
