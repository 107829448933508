import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModeloService from './../../../services/JasmineModel';
import AlertService from 'src/services/AlertService';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    paddingRight: 27,
  },
  paper: {
    width: 320,
    height: 330,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  items: {
      paddingRight: 27,
  }
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferList({onChange}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [cargando, setCargando] = useState(true);
  const [modelos, setModelos] = useState([]);

  const handleToggle = modelo_id => () => {
    const currentIndex = checked.indexOf(modelo_id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(modelo_id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };
  
  const cargarModelos = () => {
    setCargando(true);
    ModeloService
    .obtener('', 1000, 1)
    .then((response) => {
        setCargando(false);
        if(response.success){
            setModelos(response.data.rows);
            setLeft(response.data.rows.map( modelo => modelo.id));
        }else{
          AlertService.info(response.message,'Información');
        }
    });
  }

  const getModelo = modelo_id => {
      for(let i=0; i<modelos.length; i++){
          if(modelos[i].id === modelo_id){
              return modelos[i];
          }
      }
      return null;
  }

  useEffect( () => {
    cargarModelos();
  }, []);

  useEffect( () => {
    const nicknames = right.map( modelo_id => {
      return getModelo(modelo_id).nickname
    });
    onChange(nicknames);
  }, [right]);

  const customItem = modelo_id => {
        const modelo = getModelo(modelo_id);
        const labelId = `transfer-list-item-${modelo.id}-label`;
        
        return (
            <ListItem key={modelo.id} role="listitem" button onClick={handleToggle(modelo.id)}>
            <ListItemIcon>
                <Checkbox
                checked={checked.indexOf(modelo.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
                />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${modelo.nickname}`} />
            </ListItem>
        );
  };

  const customList = items => {
      
      return (
        <Paper className={classes.paper}>
            <List dense component="div" role="list" className={classes.items}>
                {items.map(modelo_id => {
                    return customItem(modelo_id);
                })}
                <ListItem />
            </List>
        </Paper>
        );
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
      
      <Backdrop className={classes.backdrop} open={cargando} onClick={()=>{}}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}