import palette from '../palette';

export default {
  root: {
    color: palette.primary.main,
    borderBottom: '1px solid black',
    padding: 5,
    background: '#F0F0FF',
  },
};
